import {
  EBattleTarget,
  IBattle,
  IBattleProfile,
  IBattleScore,
  IRound,
  IUserBattleScore,
} from 'interface/battle';
import api from './APIService';
import { handleApiError } from './errorHandlerService';
import { IUser } from 'interface/user';

export const createBattleProfile = async (): Promise<IBattleProfile | null> => {
  try {
    const response = await api.get<IBattleProfile>('battle-profiles/create');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const getBattleProfile = async (): Promise<IBattleProfile | null> => {
  try {
    const response = await api.get<IBattleProfile>('battle-profiles/my');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const getBattles = async (page: number, limit: number): Promise<IBattle[]> => {
  try {
    const response = await api.get<IBattle[]>(`/battles?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return [];
  }
};

export const getCurrentBattle = async (): Promise<IBattle | null> => {
  try {
    const response = await api.get<IBattle>('battles/current');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const checkCurrentBattle = async (): Promise<boolean> => {
  try {
    const response = await api.get<boolean>('battles/check-current');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return false;
  }
};

export const getBattleRounds = async (id: number): Promise<IRound[] | []> => {
  try {
    const response = await api.get<IRound[]>(`battles/${id}/rounds`);
    return response.data;
  } catch (error: any) {
    return [];
  }
};

export const createAutoBattle = async (): Promise<IBattle | null> => {
  try {
    const response = await api.get<IBattle>('battles/create-auto');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const getLastAutoBattle = async (purchaseId: string): Promise<IBattle | null> => {
  try {
    const response = await api.get<IBattle>(`/battles/last-auto?purchaseId=${purchaseId}`);
    return response.data;
  } catch (error: any) {
    return null;
  }
};

export const createBattle = async (timeout: number = 20000): Promise<IBattle | null> => {
  api.defaults.timeout = timeout;
  try {
    const response = await api.get<IBattle>('battles/create');
    return response.data;
  } catch (error: any) {
    return null;
  }
};

export const createBattleRound = async (
  battleId: number,
  playerAttackTarget: EBattleTarget,
  playerDefenseTarget: EBattleTarget,
  playerDodgeTarget: EBattleTarget,
): Promise<IBattle | null> => {
  try {
    const response = await api.post<IBattle>(`battles/${battleId}/rounds`, {
      playerAttackTarget,
      playerDefenseTarget,
      playerDodgeTarget,
    });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const fetchLeaderboard = async (
  page: number,
  limit: number,
): Promise<{ records: IBattleScore[]; total: number; totalPlayers: string }> => {
  try {
    const response = await api.get<{
      records: IBattleScore[];
      total: number;
      totalPlayers: number;
    }>(`/battle-profiles/leaderboard?page=${page}&limit=${limit}`);

    return {
      records: response.data.records,
      total: response.data.total,
      totalPlayers: response.data.totalPlayers?.toString() || '0',
    };
  } catch (error: any) {
    handleApiError(error);
    return { records: [], total: 0, totalPlayers: '0' };
  }
};

export const fetchProfilePosition = async (): Promise<IUserBattleScore> => {
  const response = await api.get<IUserBattleScore>('/battle-profiles/rank');
  return response.data;
};

export const fetchScores = async (): Promise<Record<string, number>> => {
  const response = await api.get<Record<string, number>>('/battle-profiles/leaderboard-stats');
  return response.data;
};

export const getSeasonRewards = async (): Promise<{
  user: IUser | null;
}> => {
  try {
    const response = await api.get<{ user: IUser | null }>('/battle-profiles/claim-rewards');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return { user: null };
  }
};

export const getRefill = async (): Promise<IBattleProfile | null> => {
  try {
    const response = await api.get<IBattleProfile>('/battle-profiles/refill-energy');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};
