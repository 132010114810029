import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './Button.scss';

interface IButtonProps {
  text: string | ReactNode;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  color?:
    | 'white'
    | 'grey'
    | 'primary'
    | 'secondary'
    | 'red'
    | 'yellow'
    | 'stars'
    | 'ice'
    | 'ice-blue'
    | 'ice-blue-2'
    | 'ice-yellow';
  style?: Record<string, string>;
}

const Button: React.FC<IButtonProps> = ({
  text,
  onClick,
  disabled,
  className,
  color = 'white',
  style = {},
}) => {
  return (
    <button
      style={style}
      className={classNames(className, `button-color--${color}`)}
      onClick={onClick}
      disabled={disabled}>
      {(color === 'ice' || color === 'ice-blue') && <div className="ice-spikes" />}
      {color === 'ice-blue-2' && <div className="ice-spikes ice-spikes-2" />}
      {text}
    </button>
  );
};

export default Button;
