import React, { memo } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import ProgressBar from 'components/ProgressBar';
import Tasks from 'components/Tasks';
import { getTaskIcon } from 'constants/tasks';
import { ETaskSlug, ETaskStatus, ITaskProgress } from 'interface/task';
import { formatNumber } from 'utils/formatNumber';
import Claim from 'components/Claim';
import useMainStore from 'store/main.store';
import useBalanceStore from 'store/balance.store';
import { startBasicTask, createBasicTask } from 'api/tasks';
import useTasksStore from 'store/tasks.store';
import Header from 'components/Header';
import {
  getGeneralTasksProgress,
  getGeneralTasksRender,
  isAllGeneralTasksCompleted,
} from 'store/tasks.selector';
import Boosts from 'components/Boosts';
import NFTStaking from 'components/NFTStaking';
import useFarmingStore from 'store/farming.store';
import AutoClaimButton from './components/AutoClaimButton';
import Button from 'components/Button';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import { showErrorToast } from 'utils/toastUtils';
import { useBattleOpen } from 'hooks/snow/useBattleOpen';

import './Home.scss';

const Home: React.FC = () => {
  const tg = useMainStore(({ tgWebApp }) => tgWebApp);
  const coins = useBalanceStore(({ coins }) => coins);
  const farming = useFarmingStore(({ farming }) => farming);
  const autoClaimAvailableUntil = useMainStore(({ user }) => user?.autoClaimAvailableUntil);
  const generalTasksRender = useTasksStore(getGeneralTasksRender);
  const generalTasksProgress = useTasksStore(getGeneralTasksProgress);
  const isGeneralCompleted = useTasksStore(isAllGeneralTasksCompleted);
  const updateGeneralTaskStatus = useTasksStore(
    ({ updateGeneralTaskStatus }) => updateGeneralTaskStatus,
  );
  const updateGeneralTask = useTasksStore(({ updateGeneralTask }) => updateGeneralTask);
  const showModal = useModalStore(({ showModal }) => showModal);
  const { showAirdropAndPvPModal } = useBattleOpen();

  const onGeneralTaskClick = async (task: ITaskProgress) => {
    if (
      task.status === ETaskStatus.VERIFY_REQUESTED ||
      task.status === ETaskStatus.VERIFY_REJECTED
    ) {
      showModal(EModals.TASK_VERIFICATION_MODAL, { task, showAirdropAndPvPModal });
      return;
    }

    if (task.status === ETaskStatus.INITIAL) {
      let taskId = task.id;
      if (taskId === 'DEFAULT_ID') {
        const taskResp = await createBasicTask(task.template.slug);
        if (taskResp) {
          taskId = taskResp.id;
          updateGeneralTask(task.template.slug, taskResp);
        }
      }
      if (taskId) {
        await startBasicTask(taskId);
      } else {
        showErrorToast('Failed to start task, please try again later');
        return;
      }
    }

    if (task.template.slug === ETaskSlug.BASIC_BATTLE_ACCOUNT) {
      showAirdropAndPvPModal();
    } else {
      if (tg?.openLink) {
        if (
          task.template.slug === ETaskSlug.BASIC_TG ||
          task.template.slug === ETaskSlug.BASIC_CHAT
        ) {
          tg.openTelegramLink(task.template.url);
        } else {
          tg.openLink(task.template.url);
        }
      } else {
        window.open(task.template.url, '_blank', 'noopener noreferrer');
      }
    }
    updateGeneralTaskStatus(task.template.slug, ETaskStatus.VERIFY_REQUESTED);
  };

  const isWithBoosts = !!farming;

  return (
    <motion.div
      className={classNames('home-page', { 'home-page--with-boosts': isWithBoosts })}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}>
      <Header />
      <div className="pitchtalk-points">
        <h4>
          <span>Your PitchTalk Points</span>
          <AutoClaimButton
            endDate={autoClaimAvailableUntil ? new Date(autoClaimAvailableUntil) : null}
          />
        </h4>
        <span>
          <b>{formatNumber(coins)}</b>
          <img src="images/point.png" alt="Points" />
        </span>
      </div>
      <ProgressBar />
      <Claim />
      <Button color="ice" text="AirDrop and PvP Battles" onClick={showAirdropAndPvPModal} />
      {/* <Button color="ice" text="Making Snowballs..." onClick={() => {}} disabled={true}/> */}
      {isWithBoosts ? <Boosts /> : null}
      {!isGeneralCompleted ? (
        <Tasks
          label="General"
          completed={generalTasksProgress}
          className="general-tasks-list"
          isRow>
          {generalTasksRender.map((task, i) => (
            <li
              key={task.id + i}
              className={classNames(task.status ? task.status : '', task.template.slug)}
              onClick={() => onGeneralTaskClick(task)}>
              <img src={task.template.image} alt="" />
              <p className="task">{task.template.title}</p>
              {!!task.template.rewardTickets && (
                <p className="reward reward-tickets">
                  <img src="images/ticket.png" alt="Point" />
                  <span>+{formatNumber(task.template.rewardTickets)}</span>
                </p>
              )}
              <p className="reward">
                <img src="images/point.png" alt="Point" />
                <span>+{formatNumber(task.template.rewardCoins)}</span>
              </p>
              {task.status && task.status !== ETaskStatus.INITIAL && (
                <p className="status-icon">
                  <img src={getTaskIcon(task.status)} alt="" />
                </p>
              )}
              {task.isRequiredForBattles && (
                <p className="required-for-battles-icon">
                  <img width={24} height={24} src="/images/snow-season/snowflake.gif" alt="" />
                </p>
              )}
            </li>
          ))}
        </Tasks>
      ) : (
        <NFTStaking />
      )}
    </motion.div>
  );
};

export default memo(Home);
