import { EProductId, EProductType, IProduct } from 'interface/product';
import { create } from 'zustand';

interface IProductsStore {
  productId: EProductId | null;
  purchaseId: string | null;
  isPurchasing: boolean;
  products: IProduct[];
  setProductId: (productId: EProductId | null) => void;
  setPurchaseId: (id: string | null) => void;
  setIsPurchasing: (isPurchasing: boolean) => void;
  setProducts: (products: IProduct[]) => void;
}

const useProductsStore = create<IProductsStore>((set) => ({
  products: [],
  productId: null,
  purchaseId: null,
  isPurchasing: false,
  setProducts: (products: IProduct[]) => set(() => ({ products })),
  setProductId: (productId: EProductId | null) => set(() => ({ productId })),
  setPurchaseId: (id: string | null) => set(() => ({ purchaseId: id })),
  setIsPurchasing: (isPurchasing: boolean) => set(() => ({ isPurchasing })),
}));

export default useProductsStore;
