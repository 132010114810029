import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import { defaultAnimation } from 'styles/animations';
import useSWR from 'swr';
import classNames from 'classnames';
import { fetchLeaderboard, fetchProfilePosition, fetchScores } from 'api/battle';
import Button from 'components/Button';
import { formatNumber } from 'utils/formatNumber';
import useAppStore from 'store/app.store';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import LeaderBoardItem from './LeaderBoardItem';

import './Leaderboard.scss';

const ITEMS_PER_PAGE = 10;

const getKey = (pageIndex: number, previousPageData: any[]) => {
  if (previousPageData && !previousPageData.length) return null;
  return `/profile-rank?page=${pageIndex + 1}&limit=${ITEMS_PER_PAGE}`;
};

const Leaderboard: React.FC = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const settings = useAppStore((state) => state);
  const showModal = useModalStore((state) => state.showModal);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [isParallax, setIsParallax] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const {
    data = [],
    error,
    setSize,
    isValidating,
  } = useSWRInfinite(
    (pageIndex, previousPageData) => getKey(pageIndex, previousPageData),
    async (url) => {
      if (settings.isLeaderboardClosed) {
        return [];
      }
      const { records, totalPlayers } = await fetchLeaderboard(
        parseInt(url.split('page=')[1].split('&')[0]),
        ITEMS_PER_PAGE,
      );

      setTotalPlayers(parseInt(totalPlayers));
      return records;
    },
    { revalidateOnFocus: false, revalidateOnMount: true },
  );

  const { data: profilePosition } = useSWR('/battle-profiles/rank', fetchProfilePosition, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
  });

  const { data: scores } = useSWR('/battle-profiles/leaderboard-stats', fetchScores, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
  });

  const canLoadMore = data && data[data.length - 1]?.length === ITEMS_PER_PAGE;

  const leaders = data ? data.flat() : [];
  const isLoading = isValidating || (!data && !error);

  const handleGetSeasonInfo = () => {
    showModal(EModals.SEASON_REWARDS_INFO);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isLoading) return;
      const currentScrollY = contentRef.current?.scrollTop || 0;
      if (currentScrollY > lastScrollY) {
        setIsParallax(true);
      } else {
        setIsParallax(false);
      }
      setLastScrollY(currentScrollY);
    };

    contentRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      contentRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, isLoading]);

  useEffect(() => {
    const footer = document.querySelector('footer');
    if (footer) {
      footer.style.position = 'absolute';
      footer.style.bottom = isParallax ? '-116px' : '0';
      footer.style.transition = 'bottom 0.5s linear';
    }

    return () => {
      if (footer) {
        footer.style.position = '';
        footer.style.bottom = '';
        footer.style.transition = '';
      }
    };
  }, [isParallax]);

  return (
    <motion.div
      className={classNames('snow-leaderboard', 'snow-leaderboard-page')}
      {...defaultAnimation}>
      {settings.isLeaderboardClosed ? (
        <div className="snow-leaderboard__coming-soon">
          <h2 className="fs-32 fw-700">Leaderboard</h2>
          <p className="fs-16 fw-500">Coming soon</p>
        </div>
      ) : (
        <div className="snow-leaderboard__content" ref={contentRef}>
          <div className="snow-leaderboard__season-info">
            <div className="snow-leaderboard__season-info-title">
              <h2 className="fs-32 fw-700">Season #{settings.season}</h2>
              <p className="fs-14 fw-500">Rise to the Top and Earn Exclusive Rewards!</p>
            </div>
            <Button
              className="snow-leaderboard__season-info-button fs-12 fw-600"
              color="white"
              text={
                <>
                  <img
                    className="snow-leaderboard__season-info-button-gem-icon"
                    src="/images/snow-season/gem.png"
                    alt="Gems"
                    loading="lazy"
                  />
                  Season Rewards
                  <img
                    className="snow-leaderboard__season-info-button-arrow-icon"
                    src="/images/arrow.svg"
                    alt="Arrow"
                    loading="lazy"
                  />
                </>
              }
              onClick={handleGetSeasonInfo}
              disabled={settings.isLeaderboardClosed}
            />
            <p className="snow-leaderboard__season-info__total-users fs-16 fw-500">
              Total players: <span>{formatNumber(totalPlayers)}</span>
            </p>
          </div>

          <div className="snow-leaderboard__leader-list">
            {leaders.map((leader, i) => (
              <LeaderBoardItem
                key={i}
                leader={leader}
                isUser={+leader.rank === +(profilePosition?.rank || 0)}
                scores={scores}
              />
            ))}

            {isLoading && (
              <div className="list-loading">
                <div className="loading-circle">
                  <div className="loading-inner" />
                </div>
              </div>
            )}
          </div>
          {canLoadMore && !isLoading && (
            <Button
              color="white"
              className="load-more-btn"
              text="Load more"
              onClick={() => setSize((prevSize) => prevSize + 1)}
            />
          )}
        </div>
      )}
      <motion.div
        className="snow-leaderboard__current-user"
        animate={{ bottom: isParallax ? '0' : '116px' }}
        transition={{ duration: 0.5, ease: 'linear' }}>
        <LeaderBoardItem
          leader={{
            rank: profilePosition?.rank || 0,
            score: profilePosition?.score || 0,
            count: parseInt(profilePosition?.players || '0'),
          }}
          scores={scores}
          isUser
        />
      </motion.div>
    </motion.div>
  );
};

export default Leaderboard;
