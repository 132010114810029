export const MAX_ENERGY = 5; // 5 games

export const HOURS_TO_REFILL = 3; // 3 hour

export const MAX_CHANCE = 25; // max percent of luck

export const MAX_BATTLE_ROUNDS = 3; // 3 rounds in one battle

export const LEVEL_COLORS = {
  1: { main: '#F38BAF', sub: '#F8BBD0', white: '#FFEBEE' },
  2: { main: '#3A97AA', sub: '#68C3D4', white: '#A4E1EA' },
  3: { main: '#D4792B', sub: '#FF9A4F', white: '#FFC98A' },
  4: { main: '#D0BC42', sub: '#F4E76E', white: '#FBF4A1' },
  5: { main: '#8AB33F', sub: '#B2E556', white: '#D1F285' },
  6: { main: '#418759', sub: '#5EB27B', white: '#82D4A1' },
  7: { main: '#CA8CF4', sub: '#F9E6FF', white: '#FFFFFF' },
  8: { main: '#6C3CB0', sub: '#B588EF', white: '#F1D9FA' },
  9: { main: '#1C334A', sub: '#4778A6', white: '#77A0C6' },
  10: { main: '#C55309', sub: '#F8C40F', white: '#FEF9DB' },
};

export const SEASON_REWARDS = {
  1: 1_150_000,
  2: 1_000_000,
  3: 750_000,
  5: 650_000,
  10: 550_000,
  25: 450_000,
  50: 350_000,
  100: 250_000,
  150: 150_000,
  300: 50_000,
};

export const SEASON_TICKET_REWARD = 2;
export const SEASON_POINT_REWARD = 250;
