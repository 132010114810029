import { useCallback } from 'react';
import useMainStore from 'store/main.store';
import { logGAEvent } from 'utils/analytics';
import useModalStore from 'store/modals.store';

export const useLevelPurchase = () => {
  const closeModal = useModalStore((state) => state.closeModal);
  const level = useMainStore((state) => state.user?.level || 1);
  const updateLevel = useMainStore((state) => state.updateLevel);

  const handleLevelPurchase = useCallback(async () => {
    updateLevel(level + 1);
    logGAEvent('level_up', { level: level + 1 });
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  return handleLevelPurchase;
};
