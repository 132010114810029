import React, { useState, useEffect, memo } from 'react';
import Lottie from 'react-lottie-player';
import classNames from 'classnames';
import { EBattleTarget } from 'interface/battle';
import { AnimatePresence, motion } from 'framer-motion';
import { defaultAnimation } from 'styles/animations';

const SnowballAnimation: React.FC<{
  isAnimation?: boolean;
  isLeft?: boolean;
  isLuck?: boolean;
  isSuccessAttack?: boolean;
  isSuccessDefend?: boolean;
  isSuccessDodge?: boolean;
  target?: EBattleTarget;
}> = ({
  isAnimation = false,
  isLeft = false,
  isLuck = true,
  target,
  isSuccessAttack,
  isSuccessDefend,
  isSuccessDodge,
}) => {
  const [animationData, setAnimationData] = useState<any>(null);
  const [luckAnimationData, setLuckAnimationData] = useState<any>(null);

  useEffect(() => {
    const loadSnowballAnimation = async () => {
      const data = isLeft
        ? await import('assets/animations/left_snowball.json')
        : await import('assets/animations/right_snowball.json');
      setAnimationData(data.default);
    };
    loadSnowballAnimation();

    const loadLuckAnimation = async () => {
      const data = isLeft
        ? await import('assets/animations/luck1.json')
        : await import('assets/animations/luck2.json');
      setLuckAnimationData(data.default);
    };
    loadLuckAnimation();
  }, [isLeft]);

  return (
    <div className="snow-animation">
      {isAnimation && animationData && (
        <Lottie
          className={classNames('snowball-animation', {
            'snowball-animation--left': isLeft,
            'snowball-animation--right': !isLeft,
            'snowball-animation--head': target === EBattleTarget.HEAD,
            'snowball-animation--body': target === EBattleTarget.BODY,
            'snowball-animation--legs': target === EBattleTarget.LEGS,
          })}
          animationData={animationData}
          play
        />
      )}
      {isLuck && luckAnimationData && (
        <Lottie
          play
          className={classNames('luck-animation', {
            'luck-animation--left': isLeft,
            'luck-animation--right': !isLeft,
          })}
          animationData={luckAnimationData}
        />
      )}
      <AnimatePresence mode="wait" initial={true}>
        {isSuccessAttack && (
          <motion.img
            {...defaultAnimation}
            className={classNames('result-animation', 'result-animation--attack-success', {
              'result-animation--left': isLeft,
              'result-animation--right': !isLeft,
              'result-animation--head': target === EBattleTarget.HEAD,
              'result-animation--body': target === EBattleTarget.BODY,
              'result-animation--legs': target === EBattleTarget.LEGS,
            })}
            src={`/images/snow-season/battle/attack-success.png`}
            alt=""
          />
        )}
        {isSuccessDefend && (
          <motion.img
            {...defaultAnimation}
            className={classNames('result-animation', 'result-animation--defend-success', {
              'result-animation--left': isLeft,
              'result-animation--right': !isLeft,
              'result-animation--head': target === EBattleTarget.HEAD,
              'result-animation--body': target === EBattleTarget.BODY,
              'result-animation--legs': target === EBattleTarget.LEGS,
            })}
            src={`/images/snow-season/battle/defend-success.png`}
            alt=""
          />
        )}
        {isSuccessDodge && (
          <motion.img
            {...defaultAnimation}
            className={classNames('result-animation', 'result-animation--dodge-success', {
              'result-animation--left': isLeft,
              'result-animation--right': !isLeft,
              'result-animation--head': target === EBattleTarget.HEAD,
              'result-animation--body': target === EBattleTarget.BODY,
              'result-animation--legs': target === EBattleTarget.LEGS,
            })}
            src={`/images/snow-season/battle/dodge-success.png`}
            alt=""
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default memo(SnowballAnimation);
