import React from 'react';
import classNames from 'classnames';
import { formatNumber } from 'utils/formatNumber';
import { getLeaderIcon } from 'utils/leaderboard';
import { getLeaderTitle } from 'utils/leaderboard';
import CurrentUser from './CurrentUser';
import { IBattleScore } from 'interface/battle';

interface ILeaderBoardItemProps {
  leader: IBattleScore;
  isUser: boolean;
  scores?: Record<string, number>;
}

const LeaderBoardItem: React.FC<ILeaderBoardItemProps> = ({ leader, isUser, scores }) => {
  const rank = leader.rank;
  return (
    <div
      className={classNames(
        'snow-leaderboard__leader-item',
        `snow-leaderboard__leader-item--position-${rank}`,
      )}>
      <div className="snow-leaderboard__leader-item__info">
        <div className="snow-leaderboard__leader-item__info-left fs-16 fw-500">
          <p>#{rank}</p>
          <img src={`/${getLeaderIcon(rank)}`} alt="" loading="lazy" />
          <p className="fw-700">{getLeaderTitle(rank)}</p>
        </div>
        <div className="snow-leaderboard__leader-item__info-right">
          <img src="/images/snow-season/gem.png" alt="Gems" loading="lazy" />
          <p className="fs-12 fw-500">{formatNumber(leader.score, 'long', 'space')}</p>
          <p className="snow-leaderboard__leader-item__info-right-players fs-12 fw-500">
            <span>Players:</span> <b>{formatNumber(leader.count, 'long', 'space')}</b>
          </p>
        </div>
      </div>
      {isUser && <CurrentUser rank={rank} gemsAmount={leader.score} scores={scores} />}
    </div>
  );
};

export default LeaderBoardItem;
