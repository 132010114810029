import { LEVELS } from 'constants/levels';

export const MAX_RANK = 600;

export const getLeaderIcon = (position: number) => {
  if (position === 1) return LEVELS[10].levelImage;
  if (position === 2) return LEVELS[9].levelImage;
  if (position === 3) return LEVELS[8].levelImage;
  if (position >= 4 && position <= 10) return LEVELS[7].levelImage;
  if (position >= 11 && position <= 25) return LEVELS[6].levelImage;
  if (position >= 26 && position <= 50) return LEVELS[5].levelImage;
  if (position >= 51 && position <= 100) return LEVELS[4].levelImage;
  if (position >= 101 && position <= 400) return LEVELS[3].levelImage;
  if (position >= 401 && position <= MAX_RANK) return LEVELS[2].levelImage;
  return LEVELS[1].levelImage;
};

export const getLeaderTitle = (position: number) => {
  if (position === 1) return LEVELS[10].name;
  if (position === 2) return LEVELS[9].name;
  if (position === 3) return 'Hunter';
  if (position >= 4 && position <= 10) return 'Guru';
  if (position >= 11 && position <= 25) return 'Insider';
  if (position >= 26 && position <= 50) return 'Flipper';
  if (position >= 51 && position <= 100) return 'Farmer';
  if (position >= 101 && position <= 400) return LEVELS[3].name;
  if (position >= 401 && position <= MAX_RANK) return 'Trader';
  return LEVELS[1].name;
};

export const getLeaderTop = (position: number) => {
  let top = 0;

  if (position === 1) top = 1;
  if (position === 2) top = 2;
  if (position === 3) top = 3;
  if (position >= 4 && position <= 10) top = 10;
  if (position >= 11 && position <= 25) top = 25;
  if (position >= 26 && position <= 50) top = 50;
  if (position >= 51 && position <= 100) top = 100;
  if (position >= 101 && position <= 400) top = 400;
  if (position >= 401 && position <= MAX_RANK) top = MAX_RANK;

  return top ? `TOP  ${top}  /` : '';
};

export const getPlayersNextRank = (position: number, scores: Record<string, number>) => {
  let playerNextRank = 0;

  if (position === 1) playerNextRank = 1;
  if (position === 2) playerNextRank = 1;
  if (position === 3) playerNextRank = 2;
  if (position >= 4 && position <= 10) playerNextRank = 3;
  if (position >= 11 && position <= 25) playerNextRank = 10;
  if (position >= 26 && position <= 50) playerNextRank = 25;
  if (position >= 51 && position <= 100) playerNextRank = 50;
  if (position >= 101 && position <= 400) playerNextRank = 100;
  if (position >= 401 && position <= 601) playerNextRank = 400;
  if (position >= 601) playerNextRank = 600;

  return { nextRank: playerNextRank, nextScore: scores[playerNextRank] };
};
