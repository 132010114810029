import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Modals from 'providers/ModalsProvider';
import Main from 'components/Main';
import LoadingScreen from 'components/LoadingScreen';
import { preloadFonts, preloadImages } from 'utils/preload';
import { loginUser } from 'api/user';
import { getFarming, getStaking } from 'api/farming';
import { getTasks, getTaskTemplate } from 'api/tasks';
import useMainStore from 'store/main.store';
import useBalanceStore from 'store/balance.store';
import useFarmingStore from 'store/farming.store';
import { showErrorToast } from 'utils/toastUtils';
import { setItem } from 'utils/storage';
import { IDailyReward } from 'interface/user';
import { ETaskSlug, ETaskStatus, ETaskType } from 'interface/task';
import Home from 'pages/home';
import Stats from 'pages/stats';
import Referrals from 'pages/referrals';
import Template from 'pages/template';
import Levels from 'pages/levels';
import Tasks from 'pages/tasks';
import NFTPage from 'pages/nft';
import AdminPanel from 'pages/admin/panel';
import AdminDaily from 'pages/admin/daily';
import AdminGift from 'pages/admin/gift';
import Snow from 'pages/snow-season';
import Leaderboard from 'pages/snow-season/leaderboard';
import RoundPage from 'pages/snow-season/round';
import History from 'pages/snow-season/history';
import Tutorial from 'pages/snow-season/tutorial/Tutorial';
import SeasonEndPage from 'pages/snow-season/end/SeasonEndPage';
import Profile from 'pages/snow-season/profile/Profile';
import useTasksStore from 'store/tasks.store';
import { currentEnvironment, EEnvironment } from 'config';
import Maintenance from 'components/Maintenance';
import { WebApp } from 'global';
import useBattleStore from 'store/battle.store';
import { fetchProfilePosition, getBattleProfile } from 'api/battle';
import { isMobile } from 'utils/system';
import useProductsStore from 'store/products.store';
import { getProducts } from 'api/products';
import { logGAEvent, setGAUserId } from 'utils/analytics';
import useAppStore from 'store/app.store';

import '@near-wallet-selector/modal-ui/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/global.scss';

export const isMock = currentEnvironment === EEnvironment.MAINNET ? false : false;
const mock = '';

const getUserMockData = () => {
  const params = new URLSearchParams(mock);
  const referralCode = params.get('start_param') || '';
  const user = mock ? JSON.parse(params.get('user') || '') : null;
  const telegramId = user?.id.toString() || '';
  const username = user?.username || '';
  const hash = params.get('hash') || '';
  const photoUrl = params.toString();

  return { referralCode, telegramId, username, hash, photoUrl };
};

const getUserData = (tg: WebApp) => {
  const params = new URLSearchParams(tg.initData);
  const referralCode = params.get('start_param') || '';
  const telegramId = (tg.initDataUnsafe.user?.id || '').toString();
  const username = (tg.initDataUnsafe.user?.username || '').toString();
  const photoUrl = (tg.initDataUnsafe.user?.photo_url || '').toString();
  const hash = tg.initData;

  return { referralCode, telegramId, username, hash, photoUrl };
};

const App: React.FC = () => {
  const setTG = useMainStore((state) => state.setTG);
  const setUser = useMainStore((state) => state.setUser);
  const updateCoins = useBalanceStore((state) => state.updateCoins);
  const updateTickets = useBalanceStore((state) => state.updateTickets);
  const updateReferralRewards = useBalanceStore((state) => state.updateReferralRewards);
  const setFarming = useFarmingStore((state) => state.setFarming);
  const setStaking = useFarmingStore((state) => state.setStaking);
  const setSpeedBoostLevel = useFarmingStore((state) => state.setSpeedBoostLevel);
  const setTimeBoostLevel = useFarmingStore((state) => state.setTimeBoostLevel);
  const setIsLoadingFarming = useFarmingStore((state) => state.setIsLoading);
  const updatePartnerTaskStatus = useTasksStore((state) => state.updatePartnerTaskStatus);
  const updateTask = useTasksStore((state) => state.updateTask);
  const updateGeneralTask = useTasksStore((state) => state.updateGeneralTask);
  const setBattleProfile = useBattleStore((state) => state.setProfile);
  const setSeasonRank = useBattleStore((state) => state.setSeasonRank);
  const setProducts = useProductsStore((state) => state.setProducts);
  const settings = useAppStore((state) => state);
  const [isLoadedApp, setIsLoadedApp] = useState(false);
  const [user, setIsUser] = useState(false);
  const [isGifts, setIsGifts] = useState(false);
  const [dailyReward, setDailyReward] = useState<IDailyReward | null>(null);

  useEffect(() => {
    (async () => {
      const tg = window.Telegram.WebApp;
      tg.ready();
      tg.expand();

      const color = '#090E15';
      tg.setHeaderColor(color);
      tg.setBottomBarColor(color);
      tg.setBackgroundColor(color);

      try {
        if (isMobile()) {
          tg.requestFullscreen();
          document.documentElement.className = 'full-screen';
        }
      } catch (error) {
        console.log('Cannot request fullscreen', error);
      }

      await preloadFonts();
      if (settings.isMaintenance) return;

      await preloadImages();

      setTG(tg);
      setIsLoadedApp(true);

      const { referralCode, telegramId, username, hash, photoUrl } = isMock
        ? getUserMockData()
        : getUserData(tg);

      if (!telegramId) {
        showErrorToast('Cannot find telegram id!');
        return;
      }
      if (!username) {
        showErrorToast('Please set up your username!');
        return;
      }

      setGAUserId(telegramId);

      setItem('hash', hash);
      const userData = await loginUser(telegramId, username, hash, referralCode, photoUrl);
      if (!userData) {
        console.log('Cannot login user');
        return;
      }
      logGAEvent(userData.isNewUser ? 'sign_up' : 'login', { method: 'telegram' });

      setItem('token', userData.accessToken);
      setUser(userData.user);

      updateTickets(userData.user.tickets);
      updateCoins(userData.user.coins);
      updateReferralRewards(userData.user.referralRewards);

      const dailyReward = userData.dailyRewards;
      setDailyReward(dailyReward);

      if (userData.user.farmingId) {
        const farming = await getFarming();
        setFarming(farming);
      }
      if (userData.user.nftStakingId) {
        const staking = await getStaking();
        setStaking(staking);
      }
      if (userData.user.battleProfileId) {
        const battleProfile = await getBattleProfile();
        setBattleProfile(battleProfile);
        const userPosition = await fetchProfilePosition();
        setSeasonRank(Number(userPosition.rank));
      }
      setIsLoadingFarming(false);

      const tasks = await getTasks();
      const basicTemplates = await getTaskTemplate(ETaskType.BASIC);
      const partnerTemplates = await getTaskTemplate(ETaskType.PARTNER);
      const basicTasks = tasks.filter((task) => task.template.type === ETaskType.BASIC);
      const partnersTasks = tasks.filter((task) => task.template.type === ETaskType.PARTNER);
      basicTasks.forEach((task) => {
        updateGeneralTask(task.template.slug, task);
      });
      basicTemplates.forEach((template) => {
        updateGeneralTask(template.slug, {
          isVisible: template.isVisible,
          isRequiredForBattles: template.isRequiredForBattles,
        });
      });
      partnerTemplates.forEach((template) => {
        updateTask(template.slug, {
          isVisible: template.isVisible,
          isRequiredForBattles: template.isRequiredForBattles,
        });
      });
      partnersTasks.forEach((task) => {
        if (task.template.slug === ETaskSlug.PARTNER_SOL_HAPI_SCORE) {
          if (
            task.status === ETaskStatus.COMPLETED_CLAIMED ||
            task.status === ETaskStatus.COMPLETED_NOT_CLAIMED
          ) {
            updatePartnerTaskStatus(
              task.template.slug,
              !userData.user.solHapiScore || !userData.user.hotSolWalletAddress
                ? ETaskStatus.VERIFY_REJECTED
                : task.status,
            );
          }
        } else {
          updatePartnerTaskStatus(task.template.slug, task.status);
          updateTask(task.template.slug, {
            startedAt: task.startedAt,
          });
        }
      });

      setIsGifts(userData.user.isNewGifts);

      setSpeedBoostLevel(userData.user?.speedBoostLevel || 1);
      setTimeBoostLevel(userData.user?.timeBoostLevel || 1);

      setIsUser(!!userData.user.id);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const products = await getProducts();
      setProducts(products);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSeasonComponent = (Component: React.FC) => {
    if (Component === Leaderboard) {
      return settings.isSeasonRewardsAvailable || !settings.isSeasonEnd ? (
        <Component />
      ) : (
        <SeasonEndPage />
      );
    }
    return settings.isSeasonEnd ? <SeasonEndPage /> : <Component />;
  };

  return (
    <>
      {settings.isMaintenance ? (
        <Maintenance />
      ) : (
        <>
          <Router>
            <Routes>
              <Route
                path="/"
                element={
                  <Main
                    isLoadedApp={isLoadedApp}
                    isUser={user}
                    isGifts={isGifts}
                    dailyReward={dailyReward}
                  />
                }>
                <Route index element={user ? <Home /> : <LoadingScreen />} />
                <Route path="/stats" element={user ? <Stats /> : <LoadingScreen />} />
                <Route path="/tasks" element={user ? <Tasks /> : <LoadingScreen />} />
                <Route path="/referrals" element={user ? <Referrals /> : <LoadingScreen />} />
                <Route path="/nft" element={user ? <NFTPage /> : <LoadingScreen />} />
                <Route path="/template" element={user ? <Template /> : <LoadingScreen />} />
                <Route path="/levels" element={user ? <Levels /> : <LoadingScreen />} />
                <Route path="/admin" element={<AdminPanel />} />
                <Route path="/admin-daily" element={<AdminDaily />} />
                <Route path="/admin-gift" element={<AdminGift />} />

                <Route
                  path="/snow-season"
                  element={user ? renderSeasonComponent(Snow) : <LoadingScreen isSnow />}
                />
                <Route
                  path="/snow-season/tutorial"
                  element={user ? <Tutorial /> : <LoadingScreen isSnow />}
                />
                <Route
                  path="/snow-season/round"
                  element={user ? renderSeasonComponent(RoundPage) : <LoadingScreen isSnow />}
                />
                <Route
                  path="/snow-season/leaderboard"
                  element={user ? renderSeasonComponent(Leaderboard) : <LoadingScreen isSnow />}
                />
                <Route
                  path="/snow-season/history"
                  element={user ? renderSeasonComponent(History) : <LoadingScreen isSnow />}
                />
                <Route
                  path="/snow-season/profile"
                  element={user ? renderSeasonComponent(Profile) : <LoadingScreen isSnow />}
                />

                <Route path="/*" element={<LoadingScreen />} />
              </Route>
            </Routes>
          </Router>
          <Modals />
          <ToastContainer position="top-center" hideProgressBar />
        </>
      )}
    </>
  );
};

export default App;
