import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BattleInfo from 'components/SnowSeason/BattleInfo';
import Round from 'components/SnowSeason/Round';
import { EBattleTarget, ERoundResult, IRound } from 'interface/battle';
import useBattleStore from 'store/battle.store';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import { createBattleRound } from 'api/battle';
import { EAnimations, getAnimations } from 'utils/battle';
import { MAX_BATTLE_ROUNDS } from 'constants/battle';
import { sleep } from 'utils/time';

import './RoundPage.scss';

export type RoundType = {
  playerPoints: number;
  opponentPoints: number;
  isCurrent: boolean;
  isFinishedAnimations: boolean;
  isNextRoundButton: boolean;
  isNextRoundButtonHidden: boolean;
  result: ERoundResult | null;
  details: IRound | null;
};

const NEW_ROUND: RoundType = {
  playerPoints: 0,
  opponentPoints: 0,
  isCurrent: true,
  isFinishedAnimations: false,
  isNextRoundButton: false,
  isNextRoundButtonHidden: false,
  result: null,
  details: null,
};

const RoundPage: React.FC = () => {
  const navigate = useNavigate();
  const { showModal } = useModalStore();
  const battle = useBattleStore((state) => state.battle);
  const [currentRound, setCurrentRound] = useState(1);
  const [defenseTarget, setDefenseTarget] = useState<EBattleTarget | null>(null);
  const [dodgeTarget, setDodgeTarget] = useState<EBattleTarget | null>(null);
  const [attackTarget, setAttackTarget] = useState<EBattleTarget | null>(null);
  const [battleInfo, setBattleInfo] = useState<Record<number, RoundType>>({ 1: NEW_ROUND });
  const [isRoundInProgress, setIsRoundInProgress] = useState(false);
  const [animations, setAnimations] = useState<Record<EAnimations, boolean>>({
    [EAnimations.COUNT_DOWN]: false,
    [EAnimations.SNOWBALL_1]: false,
    [EAnimations.SNOWBALL_2]: false,
    [EAnimations.ATTACK_SUCCESS_1]: false,
    [EAnimations.DEFEND_SUCCESS_1]: false,
    [EAnimations.DODGE_SUCCESS_1]: false,
    [EAnimations.ATTACK_SUCCESS_2]: false,
    [EAnimations.DEFEND_SUCCESS_2]: false,
    [EAnimations.DODGE_SUCCESS_2]: false,
    [EAnimations.LUCK_ATTACK_1]: false,
    [EAnimations.LUCK_ATTACK_2]: false,
    [EAnimations.LUCK_DEFEND_1]: false,
    [EAnimations.LUCK_DEFEND_2]: false,
    [EAnimations.DETAILS_1]: false,
    [EAnimations.DETAILS_2]: false,
    [EAnimations.DETAILS_3]: false,
  });
  const [detailsOpacity, setDetailsOpacity] = useState<
    Record<EAnimations.DETAILS_1 | EAnimations.DETAILS_2 | EAnimations.DETAILS_3, number>
  >({
    [EAnimations.DETAILS_1]: 0,
    [EAnimations.DETAILS_2]: 0,
    [EAnimations.DETAILS_3]: 0,
  });

  const playerLevel = battle?.player?.level || 1;
  const opponentLevel = battle?.opponent.level || 1;
  const playerName = battle?.player?.username;
  const opponentName = battle?.opponent?.username;

  const selectDefense = (target: EBattleTarget | null) => setDefenseTarget(target);

  const selectDodge = (target: EBattleTarget | null) => setDodgeTarget(target);

  const selectAttack = (target: EBattleTarget | null) => setAttackTarget(target);

  const animation = (
    animationType: EAnimations,
    animation: { start: number; end: number; isActive?: boolean },
  ) => {
    if (!animation.isActive) return;
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        setAnimations((p) => ({ ...p, [animationType]: true }));
      }, animation.start);
      setTimeout(() => {
        setAnimations((p) => ({ ...p, [animationType]: false }));
        resolve();
      }, animation.end);
    });
  };

  const onStartRound = async () => {
    if (!battle || !attackTarget || !defenseTarget || !dodgeTarget || isRoundInProgress) return;

    setDefenseTarget(null);
    setAttackTarget(null);
    setDodgeTarget(null);
    setIsRoundInProgress(true);
    setDetailsOpacity({
      [EAnimations.DETAILS_1]: 0,
      [EAnimations.DETAILS_2]: 0,
      [EAnimations.DETAILS_3]: 0,
    });

    setBattleInfo((p) => ({
      ...p,
      [currentRound]: { ...p[currentRound], isNextRoundButtonHidden: true },
    }));

    const result = await createBattleRound(battle.id, attackTarget, defenseTarget, dodgeTarget);
    const currentRoundInfo = result?.rounds?.find((round) => round.number === currentRound);

    if (!currentRoundInfo || !result) return;

    const playerRoundPoints = currentRoundInfo.playerScore || 0;
    const opponentRoundPoints = currentRoundInfo.opponentScore || 0;

    setBattleInfo((p) => ({
      ...p,
      [currentRound]: {
        ...p[currentRound],
        details: currentRoundInfo,
      },
    }));

    const frames = getAnimations(currentRoundInfo);

    await animation(EAnimations.COUNT_DOWN, frames.countdown);

    await animation(EAnimations.LUCK_ATTACK_1, frames.luckAttack1);
    await animation(EAnimations.LUCK_DEFEND_2, frames.luckDefend2);
    await animation(EAnimations.SNOWBALL_1, frames.snowball1);
    await animation(EAnimations.ATTACK_SUCCESS_1, frames.attackSuccess1);
    await animation(EAnimations.DEFEND_SUCCESS_2, frames.defendSuccess2);
    await animation(EAnimations.DODGE_SUCCESS_2, frames.dodgeSuccess2);

    setDetailsOpacity({
      [EAnimations.DETAILS_1]: 1,
      [EAnimations.DETAILS_2]: 0,
      [EAnimations.DETAILS_3]: 0,
    });
    await animation(EAnimations.DETAILS_1, frames.details_1);

    await animation(EAnimations.LUCK_ATTACK_2, frames.luckAttack2);
    await animation(EAnimations.LUCK_DEFEND_1, frames.luckDefend1);
    await animation(EAnimations.SNOWBALL_2, frames.snowball2);
    await animation(EAnimations.ATTACK_SUCCESS_2, frames.attackSuccess2);
    await animation(EAnimations.DEFEND_SUCCESS_1, frames.defendSuccess1);
    await animation(EAnimations.DODGE_SUCCESS_1, frames.dodgeSuccess1);

    setDetailsOpacity({
      [EAnimations.DETAILS_1]: 1,
      [EAnimations.DETAILS_2]: 1,
      [EAnimations.DETAILS_3]: 0,
    });
    await animation(EAnimations.DETAILS_2, frames.details_2);
    setDetailsOpacity({
      [EAnimations.DETAILS_1]: 1,
      [EAnimations.DETAILS_2]: 1,
      [EAnimations.DETAILS_3]: 1,
    });
    await sleep(500);

    setBattleInfo((p) => ({
      ...p,
      [currentRound]: {
        playerPoints: playerRoundPoints,
        opponentPoints: opponentRoundPoints,
        isCurrent: true,
        isFinishedAnimations: true,
        isNextRoundButton: true,
        isNextRoundButtonHidden: false,
        details: currentRoundInfo,
        result:
          playerRoundPoints > opponentRoundPoints
            ? ERoundResult.WIN
            : playerRoundPoints < opponentRoundPoints
            ? ERoundResult.LOSE
            : ERoundResult.DRAW,
      },
    }));

    setTimeout(() => {
      if (currentRound === MAX_BATTLE_ROUNDS) {
        setTimeout(() => {
          showModal(EModals.BATTLE_RESULT_MODAL, {
            rounds: result.rounds,
            winnerId: result.winnerId,
            playerId: result.playerId,
            opponentId: result.opponentId,
            playerScore: result.playerScore,
            opponentScore: result.opponentScore,
            playerName: battle.player.username,
            opponentName: battle.opponent.username,
            playerLevel: battle.player.level,
            opponentLevel: battle.opponent.level,
          });
          navigate('/snow-season/history');
        }, 2000);
      }
    }, 500);

    setIsRoundInProgress(false);
  };

  const onNextRoundClick = () => {
    setBattleInfo((p) => ({
      ...p,
      [currentRound]: { ...p[currentRound], isCurrent: false },
      [currentRound + 1]: NEW_ROUND,
    }));
    setCurrentRound((p) => p + 1);
  };

  const isBattleFinished =
    !!battleInfo?.[MAX_BATTLE_ROUNDS]?.result &&
    !!battleInfo?.[MAX_BATTLE_ROUNDS]?.isFinishedAnimations;

  const playerPoints = Object.values(battleInfo).reduce(
    (acc, round) => (acc += round.playerPoints),
    0,
  );
  const opponentPoints = Object.values(battleInfo).reduce(
    (acc, round) => (acc += round.opponentPoints),
    0,
  );

  useEffect(() => {
    if (battle?.rounds?.length) {
      setCurrentRound(battle.rounds.length + 1);

      const initialBattleInfo = battle.rounds.reduce(
        (acc: Record<number, RoundType>, round, index) => {
          const result =
            round.playerScore > round.opponentScore
              ? ERoundResult.WIN
              : round.playerScore < round.opponentScore
              ? ERoundResult.LOSE
              : ERoundResult.DRAW;

          acc[index + 1] = {
            playerPoints: round.playerScore || 0,
            opponentPoints: round.opponentScore || 0,
            isCurrent: false,
            isFinishedAnimations: true,
            isNextRoundButton: false,
            isNextRoundButtonHidden: false,
            details: round,
            result,
          };
          return acc;
        },
        {},
      );

      initialBattleInfo[battle.rounds.length + 1] = NEW_ROUND;

      setBattleInfo(initialBattleInfo);
    }

    if (!battle) {
      navigate('/snow-season');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAnimationActive = () =>
    animations[EAnimations.COUNT_DOWN] ||
    animations[EAnimations.SNOWBALL_1] ||
    animations[EAnimations.SNOWBALL_2] ||
    animations[EAnimations.DETAILS_1] ||
    animations[EAnimations.DETAILS_2];

  const getSelectFunction = (
    isCurrent: boolean,
    selectFunction: (target: EBattleTarget | null) => void,
  ) => (isAnimationActive() ? () => {} : isCurrent ? selectFunction : () => {});

  return (
    <div className="round-page">
      <BattleInfo
        playerLevel={playerLevel}
        opponentLevel={opponentLevel}
        playerName={playerName}
        opponentName={opponentName}
        playerPoints={playerPoints}
        opponentPoints={opponentPoints}
      />
      <div className="round-page__rounds">
        {Object.entries(battleInfo)
          .sort(([a], [b]) => Number(b) - Number(a))
          .map(([key, round]) => (
            <Round
              key={round.isCurrent ? 'current' : `previous-${key}`}
              id={Number(key)}
              round={round}
              animations={animations}
              isDisabled={!defenseTarget || !attackTarget || !dodgeTarget}
              attackTarget={round.isCurrent ? attackTarget : null}
              defenseTarget={round.isCurrent ? defenseTarget : null}
              dodgeTarget={round.isCurrent ? dodgeTarget : null}
              onStartRound={round.isCurrent ? onStartRound : () => {}}
              onNextRound={round.isCurrent ? onNextRoundClick : () => {}}
              selectDefense={getSelectFunction(round.isCurrent, selectDefense)}
              selectAttack={getSelectFunction(round.isCurrent, selectAttack)}
              selectDodge={getSelectFunction(round.isCurrent, selectDodge)}
              playerLevel={playerLevel}
              opponentLevel={opponentLevel}
              isBattleFinished={isBattleFinished}
              detailsOpacity={detailsOpacity}
            />
          ))}
      </div>
    </div>
  );
};

export default memo(RoundPage);
