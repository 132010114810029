import React, { memo } from 'react';
import { motion } from 'framer-motion';
import useMainStore from 'store/main.store';
import { defaultAnimation } from 'styles/animations';

import './Profile.scss';
import Player from 'components/SnowSeason/Round/components/Player';

const Profile: React.FC = () => {
  const username = useMainStore((state) => state.user?.username);
  const level = useMainStore((state) => state.user?.level);

  return (
    <motion.div className="profile-page" {...defaultAnimation}>
      <h3 className="profile-page-coming-soon">Coming Soon</h3>
      <div className="profile-page-username">
        <span>{username}</span>
      </div>
      <div className="profile-page-equipment">
        <img src="/images/snow-season/profile/equipment-1.png" alt="equipment" />
        <img src="/images/snow-season/profile/equipment-2.png" alt="equipment" />
      </div>
      <Player level={level} />
      <div className="profile-page-stats">
        <img src="/images/snow-season/profile/stats.png" alt="stats" />
        <p>Artifacts</p>
        <img src="/images/snow-season/profile/artifacts.png" alt="artifacts" />
      </div>
    </motion.div>
  );
};

export default memo(Profile);
