import React, { memo } from 'react';
import classNames from 'classnames';

const ShieldIcon: React.FC<{
  className?: string;
  width?: number;
  height?: number;
  color?: string;
}> = ({ className, width = 14, height = 14, color = '#6FC4EE' }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    className={classNames('shield-icon', className)}>
    <g clipPath="url(#clip0_4214_25658)">
      <path
        d="M6.63186 7.71669V13.1098C6.63186 13.5569 6.26938 13.9191 5.82255 13.9191C5.6628 13.9191 5.50647 13.8719 5.37361 13.7831C2.5363 11.8919 0.832275 8.70763 0.832275 5.29774V3.05805C0.832275 2.47572 1.30441 2.00358 1.88674 2.00358C3.0775 2.00358 4.22898 1.57757 5.13319 0.802538L5.36122 0.607198C5.50067 0.487516 5.67835 0.421875 5.86209 0.421875C6.28731 0.421875 6.63186 0.766423 6.63186 1.19164V2.63389C6.63186 3.74187 5.73266 4.64371 4.62599 4.64819C4.35499 4.64924 4.12064 4.85223 4.09322 5.12164C4.06106 5.43799 4.31782 5.70108 4.62731 5.70266C5.73345 5.70793 6.63186 6.60924 6.63186 7.71669ZM7.68633 7.71669C7.68633 6.60924 8.58473 5.70767 9.69087 5.70239C10.0006 5.70081 10.2574 5.43772 10.225 5.12138C10.1975 4.85196 9.96319 4.64898 9.69219 4.64792C8.58552 4.64344 7.68633 3.74161 7.68633 2.63389V1.19164C7.68633 0.766423 8.03087 0.421875 8.45609 0.421875C8.63983 0.421875 8.81751 0.487516 8.95696 0.607198L9.18499 0.802538C10.0892 1.57757 11.2407 2.00358 12.4314 2.00358C13.0138 2.00358 13.4859 2.47572 13.4859 3.05805V5.29774C13.4859 8.70737 11.7819 11.8916 8.94483 13.7831C8.81197 13.8716 8.65565 13.9191 8.49589 13.9191C8.0488 13.9191 7.68659 13.5566 7.68659 13.1098L7.68633 7.71669Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4214_25658">
        <rect
          width="13.4972"
          height="13.4972"
          fill="white"
          transform="translate(0.410522 0.421875)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default memo(ShieldIcon);
