import React, { useEffect } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import useBattleStore from 'store/battle.store';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import useCountdown from 'hooks/useCountdown';
import { formatEnergyTimeLeft, sleep } from 'utils/time';
import { HOURS_TO_REFILL, MAX_ENERGY } from 'constants/battle';
import { getBattleProfile } from 'api/battle';
import { defaultAnimation } from 'styles/animations';
import { getLeaderIcon, getLeaderTitle, getLeaderTop, MAX_RANK } from 'utils/leaderboard';

import './SeasonInfo.scss';

const ENERGY_REFRESH_INTERVAL = 3600 * HOURS_TO_REFILL;

const SeasonInfo: React.FC = () => {
  const { profile, setProfile, isRefilling, setIsRefilling, rank } = useBattleStore();
  const showModal = useModalStore((state) => state.showModal);
  const timeLeft = useCountdown(new Date(profile?.nextEnergyRefreshAt || new Date()), 1000);

  const energy = profile?.energy || 0;
  const duels = profile?.currentSeasonPlayed || 0;
  const wins = profile?.currentSeasonWins || 0;
  const draws = profile?.currentSeasonDraws || 0;

  const handleShowInfo = () => {
    showModal(EModals.SNOWBALLS_FAQ_MODAL);
  };

  const timeLeftFormatted = timeLeft ? formatEnergyTimeLeft(timeLeft) : null;
  const percentageFilled = timeLeft
    ? Math.min(100, (1 - timeLeft / ENERGY_REFRESH_INTERVAL) * 100)
    : 0;

  useEffect(() => {
    if (timeLeftFormatted && timeLeft === 1) {
      (async () => {
        try {
          await sleep(1000);
          setIsRefilling(true);
          await sleep(3000);
          const user = await getBattleProfile();
          if (user) {
            setProfile(user);
          }
        } catch (error) {
          console.error('Failed to refill energy...', error);
        } finally {
          setIsRefilling(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeftFormatted, timeLeft]);

  const showRefillModal = () => {
    if (isRefilling || (profile?.energy || 0) >= MAX_ENERGY) return;
    showModal(EModals.SNOWBALLS_REFILL_MODAL);
  };

  return (
    <div className="season-info">
      {isRefilling ? (
        <motion.div className="season-info__refilling-overlay" {...defaultAnimation}>
          <img
            className="season-info__refilling-overlay-img"
            src="/images/loading-snow.gif"
            alt=""
          />
          <h2>Making Snowballs...</h2>
        </motion.div>
      ) : null}
      <div
        className={classNames('season-info__energy', {
          'season-info__energy--refilling': (profile?.energy || 0) < MAX_ENERGY,
        })}>
        <div className="season-info__energy-icons-wrapper" onClick={showRefillModal}>
          <img
            className="season-info__energy-icon"
            src="/images/snow-season/energy.png"
            alt="Energy"
          />
          {(profile?.energy || 0) < MAX_ENERGY && !isRefilling && (
            <img className="season-info__energy-add" src="/images/snow-season/add.png" alt="+" />
          )}
        </div>
        <div className="season-info__energy-info">
          <div className="season-info__energy-info-item">
            <p className="season-info__energy-amount">
              {energy}
              <span>/{MAX_ENERGY}</span>
            </p>
            {!!timeLeft && (
              <div className="season-info__energy-time-left">
                <span>{timeLeftFormatted}</span>
                <div style={{ width: `${percentageFilled}%` }}></div>
              </div>
            )}
          </div>
          {!!profile?.energyRefills && (
            <div className="season-info__energy-info-item">
              <img width={16} height={16} src="/images/snow-season/snowflake.gif" alt="*" />
              <span className="fs-14 fw-600">Refills:</span>
              <span className="fs-14 fw-600 opacity-60">{profile?.energyRefills || 0}</span>
            </div>
          )}
        </div>
      </div>

      <div className="season-info__stats">
        <p>
          <span>Duels:</span>
          <span>{duels}</span>
        </p>
        <p>
          <span>Wins:</span>
          <span>{wins}</span>
        </p>
        <p>
          <span>Draws:</span>
          <span>{draws}</span>
        </p>
      </div>

      <div className="season-info__rating">
        <p className="season-info__rating-place">
          <span className="fs-20 fw-600">{rank ? `#${rank}` : 'N/A'}</span>
          <span className="fs-16 fw-500">Your place</span>
        </p>
        {!!rank && !!rank && rank <= MAX_RANK && (
          <p className="season-info__rating-top fs-14 fw-600">
            <span>{getLeaderTop(rank)}</span>
            <img style={{ width: 21, height: 21 }} src={getLeaderIcon(rank)} alt="" />
            <span>{getLeaderTitle(rank)}</span>
          </p>
        )}
      </div>

      <img
        className="season-info__info-icon"
        onClick={handleShowInfo}
        src="/images/snow-season/info-icon.png"
        alt="i"
      />

      <img className="season-info__ice-1" src="/images/snow-season/ice/1.png" alt="" />
      <img className="season-info__ice-2" src="/images/snow-season/ice/2.png" alt="" />
    </div>
  );
};

export default SeasonInfo;
