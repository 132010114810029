import { IUser } from 'interface/user';
import api from './APIService';
import { IFarming, INFTStaking } from 'interface/farming';
import { showErrorToast, showSuccessToast } from 'utils/toastUtils';
import { handleApiError } from './errorHandlerService';

export const createFarming = async (): Promise<IFarming | null> => {
  try {
    const response = await api.get<IFarming>('/users/create-farming');
    if (!response?.data) {
      return null;
    }
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const claimFarming = async (): Promise<IUser | null> => {
  try {
    const response = await api.get<IUser>('/users/claim-farming');
    if (!response?.data) {
      return null;
    }

    showSuccessToast('Claim success!');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const getFarming = async (): Promise<IFarming | null> => {
  try {
    const response = await api.get<IFarming>('/farmings');
    if (!response?.data) {
      return null;
    }
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const getStaking = async (): Promise<INFTStaking | null> => {
  try {
    const response = await api.get<INFTStaking>('/nft-staking');
    if (!response?.data) {
      return null;
    }
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const createStaking = async (nftId: string): Promise<INFTStaking | null> => {
  try {
    const response = await api.get<INFTStaking>(`/users/create-nft-staking?nftId=${nftId}`);
    if (!response?.data) {
      return null;
    }
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const unStakeNFT = async (): Promise<boolean> => {
  try {
    const response = await api.get<{ success: boolean }>('/users/unstake-nft-staking');
    const isSuccess = response.data.success;
    if (isSuccess) {
      showSuccessToast('NFT unstake success!');
    } else {
      showErrorToast('NFT unstake failed! Please try again later.');
    }
    return isSuccess;
  } catch (error: any) {
    handleApiError(error);
    return false;
  }
};

export const claimNFT = async (
  nftId: string,
): Promise<{ staking: INFTStaking; rewards: { coins: number; tickets: number } } | null> => {
  try {
    const response = await api.get<{
      staking: INFTStaking;
      rewards: { coins: number; tickets: number };
    }>('/users/claim-nft-staking');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};
