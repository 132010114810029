import { ETaskSlug, ETaskStatus, ETaskType, ITaskProgress } from 'interface/task';
import { DAILY_TASK_REWARD } from './main';
import { IUser } from 'interface/user';

export const HAPI_SCORE_URL = 'http://t.me/herewalletbot/app?startapp=web-score-hapi-mobi';

export const getTaskIcon = (status: ETaskStatus) => {
  switch (true) {
    case status === ETaskStatus.INITIAL:
      return 'images/arrow.svg';
    case status === ETaskStatus.COMPLETED_CLAIMED:
    case status === ETaskStatus.COMPLETED_NOT_CLAIMED:
      return 'images/task-completed-icon.png';
    case status === ETaskStatus.VERIFY_REQUESTED:
      return 'images/task-review-icon.png';
    case status === ETaskStatus.VERIFY_REJECTED:
      return 'images/task-rejected-icon.png';
  }
};

export const getDailyTaskStatus = (slug: string, tasks: ITaskProgress[]) =>
  tasks.find((task) => task.template.slug === slug)?.status || ETaskStatus.INITIAL;

export const DAILY_TASKS: ITaskProgress[] = [
  {
    id: '2',
    template: {
      id: '2',
      rewardCoins: 0,
      rewardTickets: DAILY_TASK_REWARD,
      title: 'Share X Post',
      type: ETaskType.DAILY,
      url: '',
      shortDesc: 'Share your post on X',
      longDesc: 'Share your post on X to earn rewards.',
      image: 'images/task/daily-x.png',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.DAILY_X,
      isRequiredForBattles: false,
    },
    templateId: '2',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
  },
  {
    id: '3',
    template: {
      id: '3',
      rewardCoins: 0,
      rewardTickets: DAILY_TASK_REWARD,
      title: 'Share TikTok Post',
      type: ETaskType.DAILY,
      url: '',
      shortDesc: 'Share your post on TikTok',
      longDesc: 'Share your post on TikTok to earn rewards.',
      image: 'images/task/daily-tik-tok.png',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.DAILY_TIK_TOK,
      isRequiredForBattles: false,
    },
    templateId: '3',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
  },
];

export const PARTNERS_TASKS: ITaskProgress[] = [
  {
    id: '12',
    template: {
      id: '12',
      rewardCoins: 40_000,
      rewardTickets: 40,
      rewardRefills: 2,
      rewardStars: '1-2',
      title: 'Mint SOL Hapi Score',
      type: ETaskType.PARTNER,
      url: '',
      shortDesc: 'Mint SOL Hapi Score',
      longDesc: 'Mint SOL Hapi Score to earn rewards.',
      image: 'images/hapi-score.png',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.PARTNER_SOL_HAPI_SCORE,
      isRequiredForBattles: false,
    },
    templateId: '12',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    startedAt: new Date(),
    proof: undefined,
    isVisible: true,
  },
  {
    id: '4',
    template: {
      id: '4',
      rewardCoins: 50_000,
      rewardTickets: 50,
      title: 'Mint Near Hapi Score',
      type: ETaskType.PARTNER,
      url: '',
      shortDesc: 'Mint Near Hapi Score',
      longDesc: 'Mint Near Hapi Score to earn rewards.',
      image: 'images/hapi-score.png',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.PARTNER_HAPI_SCORE,
      isRequiredForBattles: false,
    },
    templateId: '4',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    startedAt: new Date(),
    proof: undefined,
    isVisible: true,
  },
  {
    id: '5',
    template: {
      id: '5',
      rewardCoins: 50_000,
      rewardTickets: 50,
      title: 'Connect FITTON app',
      type: ETaskType.PARTNER,
      url: '',
      shortDesc: 'Connect FITTON app',
      longDesc: 'Connect FITTON app to earn rewards.',
      image: 'images/fitton-connect.png',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.PARTNER_FITTON,
      isRequiredForBattles: false,
    },
    templateId: '5',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    startedAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
    isVisible: true,
  },
  {
    id: '6',
    template: {
      id: '6',
      rewardCoins: 50_000,
      rewardTickets: 50,
      title: 'Join Yupalka App',
      type: ETaskType.PARTNER,
      url: '',
      shortDesc: 'Join Yupalka App',
      longDesc: 'Join Yupalka App and claim first card.',
      image: 'images/yupalka-connect.jpg',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.PARTNER_YUPALKA,
      isRequiredForBattles: false,
    },
    templateId: '6',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    startedAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
    isVisible: false,
  },
  {
    id: '7',
    template: {
      id: '7',
      rewardCoins: 10_000,
      rewardTickets: 10,
      title: 'Subscribe to MITTE X',
      type: ETaskType.PARTNER,
      url: 'https://x.com/mitte_gg',
      shortDesc: 'Subscribe to MITTE X',
      longDesc: 'Subscribe to MITTE X',
      image: 'images/mitte-connect.webp',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.PARTNER_MITTE,
      isRequiredForBattles: false,
    },
    templateId: '7',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    startedAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
    isVisible: false,
  },
  {
    id: '8',
    template: {
      id: '8',
      rewardCoins: 10_000,
      rewardTickets: 10,
      title: 'Join Memecat Miner',
      type: ETaskType.PARTNER,
      url: '',
      shortDesc: ' Join Memecat Miner to earn $USDT',
      longDesc: ' Join Memecat Miner to earn $USDT',
      image: 'images/memecat.jpeg',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.PARTNER_MEMECAT,
      isRequiredForBattles: false,
    },
    templateId: '8',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    startedAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
    isVisible: false,
  },
  {
    id: '9',
    template: {
      id: '9',
      rewardCoins: 50_000,
      rewardTickets: 50,
      rewardRefills: 3,
      title: 'JOIN ADDUP!',
      type: ETaskType.PARTNER,
      url: '',
      shortDesc: 'JOIN ADDUP!',
      longDesc: 'JOIN ADDUP!',
      image: 'images/adduplogo.jpg',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.PARTNER_ADDUP,
      isRequiredForBattles: false,
    },
    templateId: '9',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    startedAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
    isVisible: false,
  },
  {
    id: '10',
    template: {
      id: '10',
      rewardCoins: 30_000,
      rewardTickets: 30,
      rewardRefills: 1,
      title: 'Trade to get an AirDrop from CEX',
      type: ETaskType.PARTNER,
      url: '',
      shortDesc: 'Trade to get an AirDrop from CEX',
      longDesc: 'Trade to get an AirDrop from CEX',
      image: 'images/d3x-logo.png',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.PARTNER_D3X,
      isRequiredForBattles: false,
    },
    templateId: '10',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    startedAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
    isVisible: false,
  },
  {
    id: '11',
    template: {
      id: '11',
      rewardCoins: 30_000,
      rewardTickets: 30,
      rewardRefills: 1,
      title: 'Earn Points for $STOCAT Airdrop',
      type: ETaskType.PARTNER,
      url: '',
      shortDesc: 'Earn Points for $STOCAT Airdrop',
      longDesc: 'Earn Points for $STOCAT Airdrop',
      image: 'images/stocat-logo.jpg',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.PARTNER_STOCAT,
      isRequiredForBattles: false,
    },
    templateId: '11',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    startedAt: new Date(),
    updatedAt: new Date(),
    proof: undefined,
    isVisible: false,
  },
];

export const GENERAL_TASKS: ITaskProgress[] = [
  {
    id: 'DEFAULT_ID',
    template: {
      id: 'DEFAULT_ID',
      rewardCoins: 10_000,
      rewardTickets: 10,
      title: 'Create Battle Profile',
      type: ETaskType.BASIC,
      url: '',
      shortDesc: 'Create Battle Profile',
      longDesc: 'Create Battle Profile to earn rewards.',
      image: 'images/task/general-battle-account.svg',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.BASIC_BATTLE_ACCOUNT,
      isRequiredForBattles: true,
    },
    templateId: 'DEFAULT_ID',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    startedAt: new Date(),
    proof: undefined,
    isRequiredForBattles: true,
  },
  {
    id: 'DEFAULT_ID',
    template: {
      id: 'DEFAULT_ID',
      rewardCoins: 500,
      rewardTickets: 0,
      title: 'Follow PitchTalk on YouTube',
      type: ETaskType.BASIC,
      url: 'https://www.youtube.com/@pitchtalkofficial/featured',
      shortDesc: 'Follow PitchTalk on YouTube',
      longDesc: 'Follow PitchTalk on YouTube to earn rewards.',
      image: 'images/task/general-youtube.svg',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.BASIC_YOUTUBE,
      isRequiredForBattles: false,
    },
    templateId: '2',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    startedAt: new Date(),
    proof: undefined,
  },
  {
    id: 'DEFAULT_ID',
    template: {
      id: 'DEFAULT_ID',
      rewardCoins: 500,
      rewardTickets: 0,
      title: 'Subscribe to PitchTalk channel',
      type: ETaskType.BASIC,
      url: 'https://t.me/pitchtalk_ai',
      shortDesc: 'Subscribe to PitchTalk channel',
      longDesc: 'Subscribe to PitchTalk channel to earn rewards.',
      image: 'images/task/general-tg.svg',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.BASIC_TG,
      isRequiredForBattles: false,
    },
    templateId: 'DEFAULT_ID',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    startedAt: new Date(),
    proof: undefined,
  },
  {
    id: 'DEFAULT_ID',
    template: {
      id: 'DEFAULT_ID',
      rewardCoins: 500,
      rewardTickets: 0,
      title: 'Follow PitchTalk on X',
      type: ETaskType.BASIC,
      url: 'https://x.com/pitchtalk_com',
      shortDesc: 'Follow PitchTalk on X',
      longDesc: 'Follow PitchTalk on X to earn rewards.',
      image: 'images/task/general-x.svg',
      proofRequired: false,
      isVisible: false,
      partnerData: {},
      taskProgress: [],
      costCoins: 0,
      costTickets: 0,
      slug: ETaskSlug.BASIC_X,
      isRequiredForBattles: false,
    },
    templateId: '4',
    user: {} as IUser,
    userId: '',
    status: ETaskStatus.INITIAL,
    completedAt: undefined,
    createdAt: new Date(),
    updatedAt: new Date(),
    startedAt: new Date(),
    proof: undefined,
  },
];

export interface IGeneralTask {
  icon: string;
  label: string;
  reward: number;
  status?: ETaskStatus;
  link: string;
  type: 'TELEGRAM' | 'X' | 'YOUTUBE';
}
