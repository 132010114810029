import React from 'react';
import './Maintenance.scss';

const Maintenance: React.FC = () => {
  return (
    <div className="maintenance">
      <div className="maintenance-image-container">
        <img className="maintenance-image" src="images/maintenance.png" alt="Maintenance" />
        <img className="maintenance-shadow" src="images/maintenance-shadow.png" alt="Maintenance" />
      </div>
      <div>
        <h2>Maintenance in Progress</h2>
        <p>PitchTalk is currently undergoing maintenance to improve your experience.</p>
        <p>We'll be back shortly.</p>
      </div>
    </div>
  );
};

export default Maintenance;
