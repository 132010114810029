import { useCallback } from 'react';
import useMainStore from 'store/main.store';
import { getUser } from 'api/user';
import useModalStore from 'store/modals.store';

export const useAutoClaimPurchase = () => {
  const closeModal = useModalStore((state) => state.closeModal);
  const setUserAutoClaim = useMainStore((state) => state.setUserAutoClaim);

  const handleAutoClaimPurchase = useCallback(async () => {
    const user = await getUser();
    if (user) setUserAutoClaim(user.autoClaimAvailableUntil || null);
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return handleAutoClaimPurchase;
};
