import { memo, useEffect, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { defaultAnimation } from 'styles/animations';
import Button from 'components/Button';
import useBattleStore from 'store/battle.store';
import SeasonInfo from 'components/SnowSeason/SeasonInfo/SeasonInfo';
import useModalStore from 'store/modals.store';
import { EModals, IModalsProps } from 'interface/modals';
import Header from 'components/SnowSeason/Header/Header';
import { getCurrentBattle, checkCurrentBattle, getBattleRounds } from 'api/battle';
import { useLuck } from 'hooks/snow/useLuck';
import Stars from 'components/SnowSeason/Stars';

import './SnowSeason.scss';

const isSeasonEnded = false;
// const battleBlockTime = null; // new Date('2025-01-06T12:20:00Z');
// const seasonEndTime = null; // new Date('2025-01-06T12:30:00Z');

const SnowSeason: React.FC = () => {
  const navigate = useNavigate();
  const { showModal } = useModalStore();
  const { profile, setBattle, isRefilling } = useBattleStore();
  const [battleId, setBattleId] = useState<boolean>(false);
  const { stars, isExtraLuck, partStarIndex } = useLuck();

  const showBattleResultModal = (data: IModalsProps[EModals.BATTLE_RESULT_MODAL]) => {
    showModal(EModals.BATTLE_RESULT_MODAL, { ...data });
  };

  const onConfirmDefaultBattle = () => {
    showModal(EModals.BATTLE_WAITING_MODAL, {
      navigate,
      isCloseHidden: true,
    });
  };

  const startBattle = async () => {
    const battle = await checkCurrentBattle();

    if (!battle) {
      showModal(EModals.BATTLE_PREPARE_MODAL, {
        onConfirmDefaultBattle,
        showBattleResultModal,
      });
    } else {
      const currentBattle = await getCurrentBattle();

      if (currentBattle) {
        const currentBattleRounds = await getBattleRounds(currentBattle.id);
        currentBattle.rounds = [...currentBattleRounds];
        setBattle(currentBattle);
        navigate('/snow-season/round');
      }
    }
  };

  useEffect(() => {
    (async () => {
      const currentBattle = await checkCurrentBattle();
      if (currentBattle) {
        setBattleId(currentBattle);
      }
    })();
  }, []);

  const isButtonDisabled = useMemo(() => {
    if (battleId) return false;
    if (isSeasonEnded) return true;
    if (isRefilling) return true;
    if (profile?.energy === 0) return true;
    return false;
  }, [isRefilling, profile?.energy, battleId]);

  const buttonTitle = useMemo(() => {
    if (battleId) return 'Continue battle';
    if (isSeasonEnded) return 'Season ended';
    if (isRefilling) return 'Making Snowballs...';
    if (profile?.energy === 0) return 'No energy...';
    return 'Find an Opponent';
  }, [isRefilling, profile?.energy, battleId]);

  const handleShowTutorial = (step: number = 2) => {
    navigate(`/snow-season/tutorial?step=${step}`);
  };

  return (
    <motion.div className="snow-season-page" {...defaultAnimation}>
      <Header />
      <div className="snow-season-page-luck">
        <h3 className="title-text">
          Snowy Luck
          <img
            onClick={() => handleShowTutorial(2)}
            className="info-icon"
            src="/images/snow-season/info-icon-blue.png"
            alt="i"
          />
        </h3>
        <Stars
          stars={stars}
          isAnimate={false}
          isExtra={isExtraLuck}
          partStarIndex={partStarIndex}
        />
        <p className="luck-text">You can boost it as you play!</p>
      </div>
      <SeasonInfo />
      <Button
        className="snow-season-page__button"
        color="ice-yellow"
        disabled={profile?.luckBoostEnabled}
        text={
          <>
            <img src="/images/stars-active-2.png" alt="Star" />
            {profile?.luckBoostEnabled ? 'Extra Luck Activated!' : 'Boost Your Luck!'}
          </>
        }
        onClick={() => showModal(EModals.BOOST_LUCK_MODAL)}
      />
      <Button
        className="snow-season-page__button"
        color="ice-blue"
        text={buttonTitle}
        onClick={startBattle}
        disabled={isButtonDisabled}
      />
      {!profile?.luckBoostEnabled && (
        <p className="snow-season-page__button-text">
          Boost your luck to increase your chance of winning!
        </p>
      )}
    </motion.div>
  );
};

export default memo(SnowSeason);
