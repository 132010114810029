import { create } from 'zustand';

interface IAppStore {
  theme: string;
  isMaintenance: boolean;
  season: number;
  isLeaderboardClosed: boolean;
  isSeasonEnd: boolean;
  isSeasonRewardsAvailable: boolean;
}

const useAppStore = create<IAppStore>(() => ({
  theme: 'light',
  isMaintenance: false,
  season: 2,
  isLeaderboardClosed: false,
  isSeasonEnd: false,
  isSeasonRewardsAvailable: false,
}));

export default useAppStore;
