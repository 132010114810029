import { useNavigate } from 'react-router-dom';
import useAppStore from 'store/app.store';
import useBattleStore from 'store/battle.store';
import useMainStore from 'store/main.store';
import { preloadSnowImages } from 'utils/preload';

export const useBattleOpen = () => {
  const settings = useAppStore((state) => state);
  const navigate = useNavigate();
  const setIsSnowLoading = useBattleStore((state) => state.setIsLoading);
  const battleProfileId = useMainStore(({ user }) => user?.battleProfileId);
  const battleProfile = useBattleStore(({ profile }) => profile);
  const currentSeasonTotalPlayed = useBattleStore(
    ({ profile }) => profile?.currentSeasonPlayed || 0,
  );

  const showAirdropAndPvPModal = async () => {
    setIsSnowLoading(true);
    if (currentSeasonTotalPlayed === 0 || !battleProfileId || !battleProfile) {
      navigate('/snow-season/tutorial');
    } else if (battleProfileId || battleProfile) {
      if (settings.isSeasonEnd && !settings.isSeasonRewardsAvailable) {
        navigate('/snow-season/end');
      } else if (settings.isSeasonEnd && settings.isSeasonRewardsAvailable) {
        navigate('/snow-season/leaderboard');
      } else {
        navigate('/snow-season');
      }
    }
    preloadSnowImages().then(() => setIsSnowLoading(false));
  };

  return {
    showAirdropAndPvPModal,
  };
};
