import React from 'react';
import { formatNumber } from 'utils/formatNumber';

import './User.scss';
import PlayerHead from 'components/SnowSeason/PlayerHead';

interface IUserProps {
  username: string;

  wallet?: string;
  isShowWallet?: boolean;

  hapiScore?: number | null;
  isShowHapiScore?: boolean;

  ticketsAmount?: number;
  isShowTickets?: boolean;

  isShowSnowStats?: boolean;

  gemsAmount?: number;
  isShowGems?: boolean;
  playerLevel?: number;

  isShowSeasonStatus?: boolean;
  winsAmount?: number;
  duelsAmount?: number;

  onWalletClick?: () => void;
}

const User: React.FC<IUserProps> = ({
  username,
  isShowTickets,
  isShowGems,
  ticketsAmount,
  gemsAmount,
  isShowWallet,
  hapiScore,
  isShowHapiScore,
  wallet,
  onWalletClick,
  playerLevel,

  isShowSnowStats,
  isShowSeasonStatus,
  winsAmount,
  duelsAmount,
}) => (
  <div className="user">
    {!isShowSnowStats ? (
      <div className="user-img">{username?.[0] || ''}</div>
    ) : (
      <PlayerHead level={playerLevel || 1} />
    )}

    <div className="user-info">
      <h4>{username}</h4>
      {isShowTickets && (
        <p className="user-tickets">
          <img src="images/point.png" alt="Points" loading="lazy" />
          <span>{formatNumber(ticketsAmount)}</span>
        </p>
      )}
      {isShowSnowStats && (
        <div className="user-snow-stats">
          {isShowGems && (
            <p className="user-gems">
              <img src="/images/snow-season/gem.png" alt="Gems" loading="lazy" />
              <span>{formatNumber(gemsAmount)}</span>
            </p>
          )}
          {isShowSeasonStatus && (
            <p className="user-season-stats">
              <span>Duels:</span>
              <b>{duelsAmount}</b>
              <span>Wins:</span>
              <b>{winsAmount}</b>
            </p>
          )}
        </div>
      )}
      {isShowWallet && wallet && (
        <p className="user-wallet" onClick={onWalletClick}>
          <img src="images/hot.png" alt="Hot" loading="lazy" />
          <span>{wallet}</span>
        </p>
      )}
    </div>
    {!!isShowHapiScore && (
      <div className="hapi-score">
        <img src="images/hapi-score.png" alt="Hapi" loading="lazy" />
        <span>{hapiScore}</span>
      </div>
    )}
  </div>
);

export default React.memo(User);
