import { ETaskSlug, ETaskStatus, ETaskType, ITaskProgress, ITaskTemplate } from 'interface/task';
import api from './APIService';
import { handleApiError } from './errorHandlerService';
import { showErrorToast, showSuccessToast } from 'utils/toastUtils';
import { IUser } from 'interface/user';
import { sleep } from 'utils/time';
import { currentEnvironment, EEnvironment } from 'config';

export const submitDailyTask = async (
  slug: string,
  proof: string,
): Promise<ITaskProgress | null> => {
  try {
    const response = await api.post<ITaskProgress>('tasks/create-daily', { slug, proof });
    showSuccessToast('Success Submit');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const submitHapiScoreTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/create-partner?slug=hapi-score-connect`);
    showSuccessToast('Success Near Mint');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const submitSolHapiScoreTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(
      `tasks/create-partner?slug=sol-hapi-score-connect`,
    );
    showSuccessToast('Success Sol Mint');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const submitYupalkaTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/create-partner?slug=yupalka-connect`);
    showSuccessToast('Task initiated successfully');
    return response.data;
  } catch (error: any) {
    showErrorToast(`Something went wrong! Please try again`);
    return null;
  }
};

export const verifyYupalkaTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/verify-partner?slug=yupalka-connect`);

    const data = response.data;
    if (
      data.status === ETaskStatus.COMPLETED_CLAIMED ||
      data.status === ETaskStatus.COMPLETED_NOT_CLAIMED
    ) {
      // showSuccessToast('Task successfully verified!');
    } else {
      showErrorToast('Task verification failed! Please try again in 5 minutes.');
    }
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      showErrorToast(error.response.data.message);
    } else {
      handleApiError(error);
    }
    return null;
  }
};

export const verifyMitteTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/verify-partner?slug=mitte-connect`);

    const data = response.data;
    if (
      data.status === ETaskStatus.COMPLETED_CLAIMED ||
      data.status === ETaskStatus.COMPLETED_NOT_CLAIMED
    ) {
      // showSuccessToast('Task successfully verified!');
    } else {
      showErrorToast('Task verification failed! Please try again in 5 minutes.');
    }
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      showErrorToast(error.response.data.message);
    } else {
      handleApiError(error);
    }
    return null;
  }
};

export const submitFittonTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/create-partner?slug=fitton-connect`);
    showSuccessToast('Task initiated successfully');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const verifyFittonTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/verify-partner?slug=fitton-connect`);

    const data = response.data;
    if (
      data.status === ETaskStatus.COMPLETED_CLAIMED ||
      data.status === ETaskStatus.COMPLETED_NOT_CLAIMED
    ) {
      // showSuccessToast('Task successfully verified!');
    } else {
      showErrorToast('Task verification failed! Please try again later.');
    }
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      showErrorToast(error.response.data.message);
    } else {
      handleApiError(error);
    }
    return null;
  }
};

export const submitMeerkatTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/create-partner?slug=meerkat-connect`);
    showSuccessToast('Task initiated successfully');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const submitMitteTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/create-partner?slug=mitte-connect`);
    showSuccessToast('Task initiated successfully');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const verifyMeerkatTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/verify-partner?slug=meerkat-connect`);

    const data = response.data;
    if (
      !(
        data.status === ETaskStatus.COMPLETED_CLAIMED ||
        data.status === ETaskStatus.COMPLETED_NOT_CLAIMED
      )
    ) {
      showErrorToast('Task verification failed! Please try again later.');
    }
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      showErrorToast(error.response.data.message);
    } else {
      handleApiError(error);
    }
    return null;
  }
};

export const submitBasicTask = async (id: string): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/${id}/verify`);
    const taskStatus = response.data.status;
    if (
      taskStatus === ETaskStatus.COMPLETED_CLAIMED ||
      taskStatus === ETaskStatus.COMPLETED_NOT_CLAIMED
    ) {
      showSuccessToast('Success Task Verified');
    }
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const startBasicTask = async (id: string): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/${id}/start`);
    return response.data;
  } catch (error: any) {
    return null;
  }
};

export const createBasicTask = async (slug: ETaskSlug): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/create-basic?slug=${slug}`);
    return response.data;
  } catch (error: any) {
    return null;
  }
};

export const clearTasks = async () => {
  if (currentEnvironment !== EEnvironment.TESTNET) return;
  try {
    const response = await api.get<ITaskProgress>('tasks/clear', {});
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const getTasks = async (type?: ETaskType): Promise<ITaskProgress[]> => {
  const response = await api.get<ITaskProgress[]>(`tasks${type ? `?type=${type}` : ''}`);
  return response.data;
};

export const verifyTasks = async (): Promise<{
  coins: number;
  tickets: number;
  tasks: ITaskProgress[];
} | null> => {
  try {
    const response = await api.get<ITaskProgress[]>('tasks/verify');
    if (response.data.length) {
      const completedTasks = response.data.filter(
        (task) =>
          task.status === ETaskStatus.COMPLETED_CLAIMED ||
          task.status === ETaskStatus.COMPLETED_NOT_CLAIMED,
      );

      let user;
      if (completedTasks.length > 0) {
        await sleep(1000);
        user = await api.get<IUser>('users/me');
      }
      response.data.forEach((task) => {
        if (
          task.status === ETaskStatus.COMPLETED_CLAIMED ||
          task.status === ETaskStatus.COMPLETED_NOT_CLAIMED
        ) {
          showSuccessToast('Task successfully verified!.');
        } else {
          showErrorToast('Cannot verify task, please try again!');
        }
      });
      return {
        coins: user ? user.data.coins : 0,
        tickets: user ? user.data.tickets : 0,
        tasks: response.data,
      };
    }
    return null;
  } catch (error) {
    handleApiError(error);
    return null;
  }
};

export const verifyTaskBySlug = async (slug: string) => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/verify-partner?slug=${slug}`);
    return response.data;
  } catch (error) {
    return null;
  }
};

export const verifyTasksById = async (
  id: string,
): Promise<{
  coins: number;
  tickets: number;
  tasks: ITaskProgress;
} | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/${id}/verify`);
    if (response.data) {
      const task = response.data;
      const isCompletedTasks =
        task.status === ETaskStatus.COMPLETED_CLAIMED ||
        task.status === ETaskStatus.COMPLETED_NOT_CLAIMED;

      let user;
      if (isCompletedTasks) {
        await sleep(1000);
        user = await api.get<IUser>('users/me');
        showSuccessToast('Task successfully verified!');
      }
      return {
        coins: user ? user.data.coins : 0,
        tickets: user ? user.data.tickets : 0,
        tasks: response.data,
      };
    }
    return null;
  } catch (error) {
    handleApiError(error);
    return null;
  }
};

export const getTaskTemplate = async (type: ETaskType): Promise<ITaskTemplate[]> => {
  const response = await api.get<ITaskTemplate[]>('task-templates', {
    params: { type, status: 'active' },
  });
  return response?.data || [];
};

// ADMIN
export const getTasksList = async (page: number, limit: number): Promise<ITaskProgress[]> => {
  try {
    const response = await api.get<ITaskProgress[]>(
      `tasks/list?page=${page}&limit=${limit}&status=${ETaskStatus.VERIFY_REQUESTED}&type=${ETaskType.DAILY}`,
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return [];
  }
};

export const changeTaskStatus = async (id: string, status: ETaskStatus) => {
  try {
    const response = await api.post<ITaskProgress>(`tasks/${id}/change-status`, { status });
    showSuccessToast(
      `Success Task ${status === ETaskStatus.VERIFY_REJECTED ? 'Rejected' : 'Verified'} `,
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const submitMemecatTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/create-partner?slug=memecat-connect`);
    showSuccessToast('Task initiated successfully');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const verifyMemecatTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/verify-partner?slug=memecat-connect`);

    const data = response.data;
    if (
      !(
        data.status === ETaskStatus.COMPLETED_CLAIMED ||
        data.status === ETaskStatus.COMPLETED_NOT_CLAIMED
      )
    ) {
      showErrorToast('Task verification failed! Please try again later.');
    }
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      showErrorToast(error.response.data.message);
    } else {
      handleApiError(error);
    }
    return null;
  }
};

export const submitAddupTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/create-partner?slug=addup-connect`);
    showSuccessToast('Task initiated successfully');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const verifyAddupTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/verify-partner?slug=addup-connect`);

    const data = response.data;
    if (
      !(
        data.status === ETaskStatus.COMPLETED_CLAIMED ||
        data.status === ETaskStatus.COMPLETED_NOT_CLAIMED
      )
    ) {
      showErrorToast('Task verification failed! Please try again later.');
    }
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      showErrorToast(error.response.data.message);
    } else {
      handleApiError(error);
    }
    return null;
  }
};

export const submitD3xTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/create-partner?slug=d3x-connect`);
    showSuccessToast('Task initiated successfully');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const verifyD3xTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/verify-partner?slug=d3x-connect`);

    const data = response.data;
    if (
      !(
        data.status === ETaskStatus.COMPLETED_CLAIMED ||
        data.status === ETaskStatus.COMPLETED_NOT_CLAIMED
      )
    ) {
      showErrorToast('Task verification failed! Please try again later.');
    }
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      showErrorToast(error.response.data.message);
    } else {
      handleApiError(error);
    }
    return null;
  }
};

export const submitStocatTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/create-partner?slug=stocat-connect`);
    showSuccessToast('Task initiated successfully');
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return null;
  }
};

export const verifyStocatTask = async (): Promise<ITaskProgress | null> => {
  try {
    const response = await api.get<ITaskProgress>(`tasks/verify-partner?slug=stocat-connect`);

    const data = response.data;
    if (
      !(
        data.status === ETaskStatus.COMPLETED_CLAIMED ||
        data.status === ETaskStatus.COMPLETED_NOT_CLAIMED
      )
    ) {
      showErrorToast('Task verification failed! Please try again later.');
    }
    return response.data;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      showErrorToast(error.response.data.message);
    } else {
      handleApiError(error);
    }
    return null;
  }
};
