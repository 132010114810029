import { PT_NFT_LEVEL_BY_GRADE_SKILL } from 'constants/nft';
import { useMemo } from 'react';
import useBattleStore from 'store/battle.store';
import useFarmingStore from 'store/farming.store';
import useMainStore from 'store/main.store';
import useNFTsStore from 'store/nfts.store';
import { isAllSnowTasksCompleted } from 'store/tasks.selector';
import useTasksStore from 'store/tasks.store';

export const useLuck = () => {
  const level = useMainStore((state) => state.user?.level);
  const solHapiScore = useMainStore((state) => state.user?.solHapiScore);
  const isAllTasksCompleted = useTasksStore(isAllSnowTasksCompleted);
  const luckBoostEnabled = useBattleStore((state) => !!state.profile?.luckBoostEnabled);

  const yupNftsAmount = useNFTsStore((state) => state.yupNfts.size);

  const stakingNFTGrade = useFarmingStore((state) => state.staking?.nftGrade);
  const pitchTalkNftsLuck = stakingNFTGrade
    ? (PT_NFT_LEVEL_BY_GRADE_SKILL.get(stakingNFTGrade) || 0) * 0.5
    : 0;

  const calculateLuck = () => {
    const tasksLuck = isAllTasksCompleted ? 7.5 : 0;
    const levelLuck = level ? level : 0;
    const yupNFTsLuck = yupNftsAmount ? 2.5 : 0;
    const solHapiScoreLuck = solHapiScore ? (solHapiScore > 50 ? solHapiScore / 10 : 5) : 0;
    const updatedPitchTalkNftsLuck = pitchTalkNftsLuck >= 5 ? 5 : pitchTalkNftsLuck;
    const extraLuck = luckBoostEnabled ? 5 : 0;

    return (
      levelLuck + tasksLuck + solHapiScoreLuck + yupNFTsLuck + updatedPitchTalkNftsLuck + extraLuck
    );
  };

  const luck = calculateLuck();
  const luckStars = Math.min(Math.round(luck / 5), 5);

  const stars = useMemo(
    () => Array.from({ length: 5 }).map((_, index) => index < luckStars),
    [luckStars],
  );

  const isPartStar = luck % 5 >= 2.5 && luck % 5 < 5;
  const lastStarIndex = stars.findLastIndex((star) => !!star);

  return {
    level,
    levelSkill: level ? level * 10 : 0,
    tasksSkill: isAllTasksCompleted ? 100 : 0,
    hapiScoreSkill: solHapiScore ? (solHapiScore > 50 ? solHapiScore : 50) : 0,
    yupNFTsSkill: yupNftsAmount ? 100 : 0,
    pitchTalkNftsSkill: Math.min(pitchTalkNftsLuck * 20, 100),
    isExtraLuck: luckBoostEnabled,
    partStarIndex: isPartStar ? lastStarIndex : -1,
    stars,
  };
};
