import { IUser } from './user';

export interface IBattleProfile {
  id: string;
  user: IUser;
  userId: string;
  username: string;
  level: number;

  energy: number;
  nextEnergyRefreshAt: Date;

  score: number;
  wins: number;
  losses: number;
  draws: number;
  totalPlayed: number;

  battlesAsPlayer: IBattle[];
  battlesAsOpponent: IBattle[];

  hitChance: number;
  dodgeChance: number;

  defenseHitChance: number;
  defenseDodgeChance: number;

  luckBoostEnabled: boolean;

  firstSeasonRewardClaimed: boolean;

  currentSeasonDraws: number;
  currentSeasonLosses: number;
  currentSeasonPlayed: number;
  currentSeasonWins: number;

  energyRefills: number;
  autoBattlesAvailable: number;

  createdAt: Date;
}

export interface IBattle {
  id: number;
  player: IBattleProfile;
  opponent: IBattleProfile;
  playerId: string;
  opponentId: string;

  status: EBattleStatus;

  playerScore: number;
  opponentScore: number;

  winnerId: string;
  loserId: string;

  isRevengeAvailable: boolean;
  isRevenge: boolean;

  rounds: IRound[];

  createdAt: Date;
  updatedAt: Date;
}

export interface IRound {
  id: number;
  number: number;
  battleId: number;

  playerId: number;
  opponentId: number;

  playerScore: number;
  opponentScore: number;

  playerAttackTarget: EBattleTarget;
  opponentAttackTarget: EBattleTarget;

  playerDefenseTarget: EBattleTarget;
  opponentDefenseTarget: EBattleTarget;

  playerAttackResult: EBattleAttackResult;
  opponentAttackResult: EBattleAttackResult;

  playerDefenseResult: EBattleDefenseResult;
  opponentDefenseResult: EBattleDefenseResult;

  playerDodgeTarget: EBattleTarget;
  opponentDodgeTarget: EBattleTarget;

  createdAt: Date;
  updatedAt: Date;
}

export enum EBattleAttackResult {
  SUCCESS = 'SUCCESS',
  BLOCKED = 'BLOCKED',
  DODGED = 'DODGED',
  LUCKY_SUCCESS = 'LUCKY_SUCCESS',
  LUCKY_BLOCKED = 'LUCKY_BLOCKED',
  LUCKY_DODGED = 'LUCKY_DODGED',
  UNLUCKY_SUCCESS = 'UNLUCKY_SUCCESS',
  UNLUCKY_BLOCKED = 'UNLUCKY_BLOCKED',
  UNLUCKY_DODGED = 'UNLUCKY_DODGED',
}

export enum EBattleDefenseResult {
  BLOCKED = 'BLOCKED',
  FAILED = 'FAILED',
  DODGED = 'DODGED',
  LUCKY_BLOCKED = 'LUCKY_BLOCKED',
  LUCKY_FAILED = 'LUCKY_FAILED',
  LUCKY_DODGED = 'LUCKY_DODGED',
  UNLUCKY_BLOCKED = 'UNLUCKY_BLOCKED',
  UNLUCKY_FAILED = 'UNLUCKY_FAILED',
  UNLUCKY_DODGED = 'UNLUCKY_DODGED',
}

export enum EBattleStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum EBattleTarget {
  BODY = 'BODY',
  HEAD = 'HEAD',
  LEGS = 'LEGS',
}

export enum ERoundResult {
  WIN = 'WIN',
  DRAW = 'DRAW',
  LOSE = 'LOSE',
}

export interface IBattleScore {
  rank: number;
  score: number;
  count: number;
}

export interface IUserBattleScore {
  id: string;
  level: number;
  rank: number;
  score: number;
  username: string;
  players: string;
}

export interface IFirstSeasonResult {
  id: string;
  score: number;
  rank: number;
  username: string;
  level: number;
  winrate: number;
  wins: number;
  lose: number;
  draw: number;
  totalPlayed: number;
}
