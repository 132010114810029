import React, { memo } from 'react';
import classNames from 'classnames';

const EmptyActionIcon: React.FC<{
  className?: string;
  width?: number;
  height?: number;
  color?: string;
}> = ({ className, width = 17, height = 17, color = '#6FC4EE' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    className={classNames('empty-action-icon', className)}>
    <path
      d="M3.16675 8C2.89061 8 2.66675 8.22386 2.66675 8.5C2.66675 8.77614 2.89061 9 3.16675 9H13.8334C14.1096 9 14.3334 8.77614 14.3334 8.5C14.3334 8.22386 14.1096 8 13.8334 8H3.16675Z"
      fill={color}
    />
  </svg>
);

export default memo(EmptyActionIcon);
