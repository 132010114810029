import { IUser } from './user';

export interface IProduct {
  id: string;
  name: string;
  price: number;
  description: string;
  type: EProductType;
  requirementLevel: number;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export enum EProductType {
  LEVEL = 'LEVEL',
  SPEED_BOOST = 'SPEED_BOOST',
  TIME_BOOST = 'TIME_BOOST',
  ENERGY_REFILL = 'ENERGY_REFILL',
  AUTO_CLAIM = 'AUTO_CLAIM',
}

export enum EPurchaseStatus {
  CREATED = 'created',
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
}

export interface IPurchase {
  id: string;
  userId: string;
  user: IUser;
  productId: string;
  product: IProduct;
  purchaseUrl: string;
  status: EPurchaseStatus;
  createdAt: Date;
  updatedAt: Date;
}

export enum EProductId {
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
  LEVEL_4 = 'LEVEL_4',
  LEVEL_5 = 'LEVEL_5',
  LEVEL_6 = 'LEVEL_6',
  LEVEL_7 = 'LEVEL_7',
  LEVEL_8 = 'LEVEL_8',
  LEVEL_9 = 'LEVEL_9',
  SPEED_BOOST_6 = 'SPEED_BOOST_6',
  SPEED_BOOST_7 = 'SPEED_BOOST_7',
  SPEED_BOOST_8 = 'SPEED_BOOST_8',
  SPEED_BOOST_9 = 'SPEED_BOOST_9',
  SPEED_BOOST_10 = 'SPEED_BOOST_10',
  SPEED_BOOST_11 = 'SPEED_BOOST_11',
  SPEED_BOOST_12 = 'SPEED_BOOST_12',
  SPEED_BOOST_13 = 'SPEED_BOOST_13',
  SPEED_BOOST_14 = 'SPEED_BOOST_14',
  SPEED_BOOST_15 = 'SPEED_BOOST_15',
  SPEED_BOOST_16 = 'SPEED_BOOST_16',
  SPEED_BOOST_17 = 'SPEED_BOOST_17',
  SPEED_BOOST_18 = 'SPEED_BOOST_18',
  SPEED_BOOST_19 = 'SPEED_BOOST_19',
  SPEED_BOOST_20 = 'SPEED_BOOST_20',
  TIME_BOOST_6 = 'TIME_BOOST_6',
  TIME_BOOST_7 = 'TIME_BOOST_7',
  TIME_BOOST_8 = 'TIME_BOOST_8',
  TIME_BOOST_9 = 'TIME_BOOST_9',
  TIME_BOOST_10 = 'TIME_BOOST_10',
  TIME_BOOST_11 = 'TIME_BOOST_11',
  TIME_BOOST_12 = 'TIME_BOOST_12',
  TIME_BOOST_13 = 'TIME_BOOST_13',
  TIME_BOOST_14 = 'TIME_BOOST_14',
  TIME_BOOST_15 = 'TIME_BOOST_15',
  TIME_BOOST_16 = 'TIME_BOOST_16',
  TIME_BOOST_17 = 'TIME_BOOST_17',
  TIME_BOOST_18 = 'TIME_BOOST_18',
  TIME_BOOST_19 = 'TIME_BOOST_19',
  AUTO_CLAIM_48h = 'AUTO_CLAIM_48h',
  AUTO_CLAIM_1WEEK = 'AUTO_CLAIM_1WEEK',
  AUTO_CLAIM_1MONTH = 'AUTO_CLAIM_1MONTH',
  // AUTO_CLAIM_SUBSCRIPTION = 'AUTO_CLAIM_SUBSCRIPTION',
  BATTLE_ENERGY_REFILL = 'BATTLE_ENERGY_REFILL',
  STAR_ACCOUNT_1M = 'STAR_ACCOUNT_1M',
  BOOST_LUCK = 'BOOST_LUCK',
  AUTO_BATTLE = 'AUTO_BATTLE',
}

export const TIME_BOOST_IDS = {
  6: EProductId.TIME_BOOST_6,
  7: EProductId.TIME_BOOST_7,
  8: EProductId.TIME_BOOST_8,
  9: EProductId.TIME_BOOST_9,
  10: EProductId.TIME_BOOST_10,
  11: EProductId.TIME_BOOST_11,
  12: EProductId.TIME_BOOST_12,
  13: EProductId.TIME_BOOST_13,
  14: EProductId.TIME_BOOST_14,
  15: EProductId.TIME_BOOST_15,
  16: EProductId.TIME_BOOST_16,
  17: EProductId.TIME_BOOST_17,
  18: EProductId.TIME_BOOST_18,
  19: EProductId.TIME_BOOST_19,
};

export const SPEED_BOOST_IDS = {
  6: EProductId.SPEED_BOOST_6,
  7: EProductId.SPEED_BOOST_7,
  8: EProductId.SPEED_BOOST_8,
  9: EProductId.SPEED_BOOST_9,
  10: EProductId.SPEED_BOOST_10,
  11: EProductId.SPEED_BOOST_11,
  12: EProductId.SPEED_BOOST_12,
  13: EProductId.SPEED_BOOST_13,
  14: EProductId.SPEED_BOOST_14,
  15: EProductId.SPEED_BOOST_15,
  16: EProductId.SPEED_BOOST_16,
  17: EProductId.SPEED_BOOST_17,
  18: EProductId.SPEED_BOOST_18,
  19: EProductId.SPEED_BOOST_19,
  20: EProductId.SPEED_BOOST_20,
};

export const BOOST_LUCK_PRICE = 2;

export const AUTO_BATTLE_PRICE = 5;
