import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { defaultAnimation } from 'styles/animations';
import useBattleStore from 'store/battle.store';
import useAppStore from 'store/app.store';

import './SnowFooter.scss';

const SnowFooter: React.FC = () => {
  const settings = useAppStore((state) => state);
  const location = useLocation();
  const { isLoading } = useBattleStore();
  const [isVisible, setIsVisible] = useState(true);

  const isHomeIconActive =
    location.pathname === '/snow-season' || location.pathname === '/snow-season/round';
  const isLeaderboardIconActive = location.pathname === '/snow-season/leaderboard';
  const isHistoryIconActive = location.pathname === '/snow-season/history';
  const isProfileIconActive = location.pathname === '/snow-season/profile';
  const isTutorial = location.pathname === '/snow-season/tutorial';

  const isEnd = settings.isSeasonEnd;
  const isRewardsClaimAvailable = settings.isSeasonRewardsAvailable;
  const isOnlyLeaderboard = settings.isSeasonEnd && settings.isSeasonRewardsAvailable;

  useEffect(() => {
    if (isTutorial) {
      setIsVisible(false);
    } else {
      setTimeout(() => setIsVisible(true), 500);
    }
  }, [isTutorial]);

  if (!isVisible || isLoading || (isEnd && !isRewardsClaimAvailable)) return null;

  return (
    <motion.footer className="snow-footer" {...defaultAnimation}>
      <ul className={isOnlyLeaderboard ? 'only-leaderboard' : ''}>
        {!isOnlyLeaderboard && (
          <li className={isHomeIconActive ? 'active' : ''}>
            <NavLink to="/snow-season">
              <img
                src={`/images/snow-season/footer/home${isHomeIconActive ? '-active' : ''}.png`}
                alt="home"
              />
            </NavLink>
            {isHomeIconActive && <div className="active-indicator" />}
          </li>
        )}
        <li className={isLeaderboardIconActive ? 'active' : ''}>
          <NavLink to="/snow-season/leaderboard">
            <img
              src={`/images/snow-season/footer/leaderboard${
                isLeaderboardIconActive ? '-active' : ''
              }.png`}
              alt="leaderboard"
            />
          </NavLink>
          {isLeaderboardIconActive && <div className="active-indicator" />}
        </li>
        {!isOnlyLeaderboard && (
          <>
            <li className={isHistoryIconActive ? 'active' : ''}>
              <NavLink to="/snow-season/history">
                <img
                  src={`/images/snow-season/footer/history${
                    isHistoryIconActive ? '-active' : ''
                  }.png`}
                  alt="History"
                />
              </NavLink>
              {isHistoryIconActive && <div className="active-indicator" />}
            </li>
            <li className={isProfileIconActive ? 'active' : ''}>
              <NavLink to="/snow-season/profile">
                <img
                  src={`/images/snow-season/footer/airdrop${
                    isProfileIconActive ? '-active' : ''
                  }.png`}
                  alt="airdrop"
                />
              </NavLink>
              {isProfileIconActive && <div className="active-indicator" />}
            </li>
          </>
        )}
        <li>
          <NavLink to="/">
            <img src="/images/snow-season/footer/back.png" alt="back" />
          </NavLink>
        </li>
      </ul>
    </motion.footer>
  );
};

export default SnowFooter;
