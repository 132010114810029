import useFarmingStore from 'store/farming.store';
import useModalStore from 'store/modals.store';

export const useBoostPurchase = () => {
  const closeModal = useModalStore((state) => state.closeModal);

  const timeBoostLevel = useFarmingStore((state) => state.timeBoostLevel);
  const speedBoostLevel = useFarmingStore((state) => state.speedBoostLevel);
  const setSpeedBoostLevel = useFarmingStore((state) => state.setSpeedBoostLevel);
  const setTimeBoostLevel = useFarmingStore((state) => state.setTimeBoostLevel);

  const handleTimeBoostPurchase = async () => {
    setTimeBoostLevel(timeBoostLevel + 1);
    closeModal();
  };

  const handleSpeedBoostPurchase = async () => {
    setSpeedBoostLevel(speedBoostLevel + 1);
    closeModal();
  };

  return {
    handleTimeBoostPurchase,
    handleSpeedBoostPurchase,
  };
};
