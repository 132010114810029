import { useCallback } from 'react';
import useBattleStore from 'store/battle.store';
import { getBattleProfile } from 'api/battle';
import useModalStore from 'store/modals.store';

export const useBattleEnergyPurchase = () => {
  const setProfile = useBattleStore((state) => state.setProfile);
  const closeModal = useModalStore((state) => state.closeModal);

  const handleBattleEnergyPurchase = useCallback(async () => {
    const user = await getBattleProfile();
    if (user) setProfile(user);
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return handleBattleEnergyPurchase;
};
