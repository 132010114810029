import React, { memo } from 'react';
import classNames from 'classnames';
import { EBattleTarget, IRound } from 'interface/battle';
import { getAttackPoints, getDefensePoints, isDefenseLuck, isAttackLuck } from 'utils/battle';
import ShieldIcon from 'assets/images/battle/ShieldIcon';
import SwordIcon from 'assets/images/battle/SwordIcon';
import DodgeIcon from 'assets/images/battle/DodgeIcon';
import EmptyActionIcon from 'assets/images/battle/EmptyActionIcon';

import './RoundDetails.scss';

interface IRoundDetails {
  round: IRound;
  isFull?: boolean;
}

const RoundDetails: React.FC<IRoundDetails> = ({ round, isFull = true }) => {
  const isPlayerWinner = round.playerScore > round.opponentScore;
  const isOpponentWinner = round.opponentScore > round.playerScore;

  const playerAttackPoints = getAttackPoints(round.playerAttackResult);
  const opponentAttackPoints = getAttackPoints(round.opponentAttackResult);
  const playerDefensePoints = getDefensePoints(round.playerDefenseResult);
  const opponentDefensePoints = getDefensePoints(round.opponentDefenseResult);

  const isPlayerDefenseLuck = isDefenseLuck(round.playerDefenseResult);
  const isPlayerAttackLuck = isAttackLuck(round.playerAttackResult);

  const isOpponentDefenseLuck = isDefenseLuck(round.opponentDefenseResult);
  const isOpponentAttackLuck = isAttackLuck(round.opponentAttackResult);

  const isSameTarget = round.playerAttackTarget === round.opponentAttackTarget;

  const luckImg = (isActive: boolean) => (
    <img
      className={classNames('round-details__info-item-score-luck', {
        'round-details__info-item-score-luck--active': isActive,
      })}
      src="/images/snow-season/luck-icon.png"
      alt=""
    />
  );

  const isShowIcon = (target1: string, target2?: string | null) => (icon: React.ReactNode) =>
    target2 && target1 === target2 ? icon : null;

  const getPlayerDefenseAction = (target: EBattleTarget) => {
    if (round.playerDefenseTarget === target) {
      return <ShieldIcon color="#6FC4EE" />;
    }
    if (round.playerDodgeTarget === target) {
      return <DodgeIcon color="#6FC4EE" />;
    }
    return <EmptyActionIcon />;
  };

  const getOpponentDefenseAction = (target: EBattleTarget) => {
    if (round.opponentDefenseTarget === target) {
      return <ShieldIcon color="#6FC4EE" />;
    }
    if (round.opponentDodgeTarget === target) {
      return <DodgeIcon color="#6FC4EE" />;
    }
    return <EmptyActionIcon />;
  };

  const renderPlayerIcons = (
    target: EBattleTarget,
    isAttack: boolean,
    showLuck: boolean = true,
  ) => (
    <div className="round-details__info-item-actions">
      <div>{showLuck && luckImg(isAttack ? isPlayerAttackLuck : isPlayerDefenseLuck)}</div>
      <div>{isSameTarget && isAttack ? <EmptyActionIcon /> : getPlayerDefenseAction(target)}</div>
      {/* <div>{getPlayerDefenseAction(target)}</div> */}
      {isAttack ? (
        <div>{isShowIcon(round.playerAttackTarget, target)(<SwordIcon color="#6FC4EE" />)}</div>
      ) : (
        <div></div>
      )}
    </div>
  );

  const renderOpponentIcons = (
    target: EBattleTarget,
    isAttack: boolean,
    showLuck: boolean = true,
  ) => (
    <div className="round-details__info-item-actions">
      {isAttack ? (
        <div>{isShowIcon(round.opponentAttackTarget, target)(<SwordIcon color="#6FC4EE" />)}</div>
      ) : (
        <div></div>
      )}
      <div>{isSameTarget && isAttack ? <EmptyActionIcon /> : getOpponentDefenseAction(target)}</div>
      <div>{showLuck && luckImg(isAttack ? isOpponentAttackLuck : isOpponentDefenseLuck)}</div>
    </div>
  );

  const getPlayerPoints = (isAttack: boolean) =>
    isAttack ? playerAttackPoints : playerDefensePoints;

  const getOpponentPoints = (isAttack: boolean) =>
    isAttack ? opponentAttackPoints : opponentDefensePoints;

  const getRestTargets = () =>
    Object.values(EBattleTarget).filter(
      (target) => round.playerAttackTarget !== target && round.opponentAttackTarget !== target,
    );

  const restTargets = getRestTargets();

  return (
    <div className="round-details">
      {isFull && (
        <>
          <h2 className="round-details__number">Round {round.number}</h2>
          <div className="round-details__score">
            <p
              className={classNames('round-details__score-item', {
                'round-details__score-item-winner': isPlayerWinner,
              })}>
              {round.playerScore ? `+${round.playerScore}` : round.playerScore}
            </p>
            <p>:</p>
            <p
              className={classNames('round-details__score-item', {
                'round-details__score-item-winner': isOpponentWinner,
              })}>
              {round.opponentScore ? `+${round.opponentScore}` : round.opponentScore}
            </p>
          </div>
        </>
      )}

      <div className="round-details__info-item">
        <span className="round-details__info-item-score">+{getPlayerPoints(true)}</span>
        {renderPlayerIcons(round.playerAttackTarget, true)}
        <p className="round-details__info-item-target">{round.playerAttackTarget}</p>
        {renderOpponentIcons(round.playerAttackTarget, false)}
        <span className="round-details__info-item-score">+{getOpponentPoints(false)}</span>
      </div>

      <div className="round-details__info-item">
        <span className="round-details__info-item-score">+{getPlayerPoints(false)}</span>
        {renderPlayerIcons(round.opponentAttackTarget, false)}
        <p className="round-details__info-item-target">{round.opponentAttackTarget}</p>
        {renderOpponentIcons(round.opponentAttackTarget, true)}
        <span className="round-details__info-item-score">+{getOpponentPoints(true)}</span>
      </div>

      {restTargets.map((restTarget, i) => (
        <div
          key={i}
          className={classNames('round-details__info-item', 'round-details__info-item--rest')}>
          <span className="round-details__info-item-score"></span>
          {renderPlayerIcons(restTarget, false, false)}
          <p className="round-details__info-item-target">{restTarget}</p>
          {renderOpponentIcons(restTarget, false, false)}
          <span className="round-details__info-item-score"></span>
        </div>
      ))}
    </div>
  );
};

export default memo(RoundDetails);
