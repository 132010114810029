import { create } from 'zustand';
import { IBattle, IBattleProfile } from 'interface/battle';

interface IBattleStore {
  profile: IBattleProfile | null;
  battle: IBattle | null;
  history: IBattle[];
  isLoading: boolean;
  isRefilling: boolean;
  isAutoBattling: boolean;
  rank: number;
  setProfile: (profile: IBattleProfile | null) => void;
  setBattle: (battle: IBattle) => void;
  setHistory: (history: IBattle[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsRefilling: (isRefilling: boolean) => void;
  setLuckBoostEnabled: (luckBoostEnabled: boolean) => void;
  setFirstSeasonRewardClaimed: (firstSeasonRewardClaimed: boolean) => void;
  addRefills: (refills: number) => void;
  setSeasonRank: (rank: number) => void;
  setProfileGems: (gems: number) => void;
}

const useBattleStore = create<IBattleStore>((set) => ({
  profile: null,
  battle: null,
  history: [],
  isLoading: false,
  isRefilling: false,
  isAutoBattling: false,
  position: 0,
  rank: 0,
  setProfile: (profile: IBattleProfile | null) => set(() => ({ profile })),
  setBattle: (battle: IBattle) => set(() => ({ battle })),
  setHistory: (history: IBattle[]) => set(() => ({ history })),
  setIsLoading: (isLoading: boolean) => set({ isLoading }),
  setIsRefilling: (isRefilling: boolean) => set({ isRefilling }),
  setIsAutoBattling: (isAutoBattling: boolean) => set({ isAutoBattling }),
  setLuckBoostEnabled: (luckBoostEnabled: boolean) =>
    set((state) => ({
      profile: state.profile ? { ...state.profile, luckBoostEnabled } : null,
    })),
  setFirstSeasonRewardClaimed: (firstSeasonRewardClaimed: boolean) =>
    set((state) => ({
      profile: state.profile ? { ...state.profile, firstSeasonRewardClaimed } : null,
    })),
  addRefills: (refills: number) =>
    set((state) => ({
      profile: state.profile
        ? { ...state.profile, energyRefills: state.profile.energyRefills + refills }
        : null,
    })),
  addAutoBattles: (autoBattles: number) =>
    set((state) => ({
      profile: state.profile
        ? {
            ...state.profile,
            autoBattlesAvailable: state.profile.autoBattlesAvailable + autoBattles,
          }
        : null,
    })),
  setSeasonRank: (rank: number) => set({ rank }),
  setProfileGems: (gems: number) =>
    set((state) => ({
      profile: state.profile ? { ...state.profile, gems } : null,
    })),
}));

export default useBattleStore;
