import React, { memo, useEffect } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Footer from 'components/Footer';
import { EModals } from 'interface/modals';
import useModalStore from 'store/modals.store';
import ScrollToTop from 'components/ScrollToTop';
import useBalanceStore from 'store/balance.store';
import { IDailyReward } from 'interface/user';
import { useNearWallet } from 'hooks/web3/useNearWallet';
import { getGifts } from 'api/gits';
import SnowFooter from 'components/SnowSeason/SnowFooter';
import useMainStore from 'store/main.store';
import LoadingScreen from 'components/LoadingScreen';
import { usePurchases } from 'hooks/purchases/usePurchases';

import './Main.scss';

interface IMainProps {
  isLoadedApp: boolean;
  isUser: boolean;
  isGifts: boolean;
  dailyReward?: IDailyReward | null;
}

const AnimatedOutlet = (): React.JSX.Element => {
  const location = useLocation();
  const element = useOutlet();

  return (
    <AnimatePresence mode="wait" initial={true}>
      {element && React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  );
};

const Main: React.FC<IMainProps> = ({ isLoadedApp, isUser, isGifts, dailyReward }) => {
  const location = useLocation();
  const isSnowSeason = location.pathname.includes('/snow-season');
  const addCoins = useBalanceStore((state) => state.addCoins);
  const addTickets = useBalanceStore((state) => state.addTickets);
  const showModal = useModalStore((state) => state.showModal);
  const setTheme = useMainStore((state) => state.setTheme);
  const tg = useMainStore(({ tgWebApp }) => tgWebApp);

  useEffect(() => {
    if (dailyReward && dailyReward?.loginStreak >= 1 && dailyReward.isNewDay && !isSnowSeason) {
      showModal(EModals.DAILY_REWARD, { dailyReward });
      addCoins(dailyReward.coins);
      addTickets(dailyReward.tickets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyReward?.loginStreak, dailyReward?.isNewDay]);

  useEffect(() => {
    if (isGifts) {
      getGifts().then((gifts) =>
        gifts.forEach((gift) =>
          showModal(EModals.GIFT, {
            id: gift.id,
            coins: gift.coins,
            tickets: gift.tickets,
            description: gift.description,
          }),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGifts]);

  useNearWallet();

  useEffect(() => {
    const className = isSnowSeason ? 'snow' : '';
    const color = !isSnowSeason ? '#090E15' : '#EFF8FC';
    tg?.setHeaderColor(color);
    tg?.setBottomBarColor(color);
    tg?.setBackgroundColor(color);
    setTheme(isSnowSeason ? 'snow' : 'default');

    if (isSnowSeason) {
      document.documentElement.classList.add(className);
    } else {
      document.documentElement.classList.remove('snow');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedApp, isSnowSeason, setTheme]);

  usePurchases();

  return isLoadedApp ? (
    <div className="container">
      <ScrollToTop />
      <AnimatedOutlet />
      {isUser ? isSnowSeason ? <SnowFooter /> : <Footer /> : null}
    </div>
  ) : (
    <LoadingScreen isSnow={isSnowSeason} />
  );
};

export default memo(Main);
