import React, { useEffect, useState } from 'react';
import { memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Button from 'components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { defaultAnimation } from 'styles/animations';
import classNames from 'classnames';
import { useLuck } from 'hooks/snow/useLuck';
import Stars from 'components/SnowSeason/Stars';
import { createBattleProfile } from 'api/battle';
import useBattleStore from 'store/battle.store';

import './Tutorial.scss';

const MAX_LEVEL = 6;

const Tutorial: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationStep = Number(location.search.split('=')[1]);
  const [step, setStep] = useState(0);
  const [animatedSkills, setAnimatedSkills] = useState<{ value: number; isAnimated: boolean }[]>([
    { value: 0, isAnimated: false },
    { value: 0, isAnimated: false },
    { value: 0, isAnimated: false },
    { value: 0, isAnimated: false },
    { value: 0, isAnimated: false },
  ]);
  const [animatedStars, setAnimatedStars] = useState<{ value: boolean; isAnimated: boolean }[]>([
    { value: false, isAnimated: false },
    { value: false, isAnimated: false },
    { value: false, isAnimated: false },
    { value: false, isAnimated: false },
    { value: false, isAnimated: false },
  ]);
  const {
    hapiScoreSkill,
    levelSkill,
    tasksSkill,
    yupNFTsSkill,
    pitchTalkNftsSkill,
    stars,
    isExtraLuck,
    partStarIndex,
  } = useLuck();
  const [isLoading, setIsLoading] = useState(false);
  const battleProfile = useBattleStore(({ profile }) => profile);
  const setBattleProfile = useBattleStore(({ setProfile }) => setProfile);

  const skills = [
    { type: 'level', title: 'Level:', subtitle: 'Your overall progress so far', value: levelSkill },
    {
      type: 'tasks',
      title: (
        <>
          Completed All
          <img width={16} height={16} src="/images/snow-season/snowflake.gif" alt="*" />
          Tasks:
        </>
      ),
      subtitle: 'Completed snowy adventures',
      value: tasksSkill,
    },
    {
      type: 'pitchTalkNfts',
      title: 'Staked PitchTalk NFT:',
      subtitle: 'Special collectibles boosting your power',
      value: pitchTalkNftsSkill,
    },
    {
      type: 'partnersNfts',
      title: 'YUPLand NFT:',
      subtitle: 'Support from special allies',
      value: yupNFTsSkill,
    },
    {
      type: 'hapi',
      title: 'SOL Hapi Score:',
      subtitle: 'Increase your chances of victory!',
      value: hapiScoreSkill,
    },
  ];

  const buttonText = step === 0 ? "Let's Begin" : step === 1 ? 'Got It' : 'Lets Play!';

  const handleButtonClick = async () => {
    if (step === 2) {
      try {
        setIsLoading(true);
        if (!battleProfile) {
          const profile = await createBattleProfile();
          setBattleProfile(profile);
          if (profile) {
            navigate('/snow-season');
            return;
          }
        }
        if (battleProfile) {
          navigate('/snow-season');
          return;
        }

        navigate('/');
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setStep(step + 1);
    }
  };

  const stepContent = {
    0: (
      <motion.div key="0" className="tutorial-page__content-step-0" {...defaultAnimation}>
        <h2>Welcome to the Pitchy's Snowy Adventure!</h2>
        <p>
          Help Pitchy collect treasures and <br /> test your snowy skills in friendly duels!
        </p>
        <img src="/images/snow-season/tutorial.png" alt="Tutorial" />
      </motion.div>
    ),
    1: (
      <motion.div key="1" className="tutorial-page__content-step-1" {...defaultAnimation}>
        <h2>How to Play</h2>
        <ul>
          <li>
            <b>1</b>
            <div className="tutorial-page__content-step-1__item-content">
              <p>Each duel has 3 rounds</p>
              <p>Plan your strategy to outsmart your opponent!</p>
            </div>
          </li>
          <li>
            <b>2</b>
            <div className="tutorial-page__content-step-1__item-content">
              <p>Choose your actions wisely</p>
              <p>Choose where to throw, what defend and what dodge each round.</p>
            </div>
          </li>
          <li>
            <div className="tutorial-info">
              <img src="/images/snow-season/tutorial-info.png" alt="" />
            </div>
          </li>
          <li>
            <b>3</b>
            <div className="tutorial-page__content-step-1__item-content">
              <p>Hit, dodge, and win points!</p>
              <p>The player with the highest score wins!</p>
            </div>
          </li>
          <li>
            <b>4</b>
            <div className="tutorial-page__content-step-1__item-content">
              <p>Boost your luck to gain an edge</p>
              <p>Climb the leaderboard and become the champion!</p>
            </div>
          </li>
        </ul>
      </motion.div>
    ),
    2: (
      <motion.div key="2" className="tutorial-page__content-step-2" {...defaultAnimation}>
        <h2>Discover Your Snowy Skills!</h2>
        <p className="tutorial-page__content-step-2__description">
          Pitchy is analyzing your snowy style... Let's see how lucky you are!
        </p>
        <div className="tutorial-page__content-step-2__skills">
          {skills.map((skill, index) => (
            <div key={index} className="tutorial-page__content-step-2__skills-item">
              <div className="tutorial-page__content-step-2__skills-item--title">
                <h3>{skill.title}</h3>
                <p>{skill.subtitle}</p>
              </div>
              <div
                className={classNames(
                  'tutorial-page__content-step-2__skills-item--progress',
                  `tutorial-page__content-step-2__skills-item--progress-${skill.type}`,
                )}>
                <motion.div
                  className="tutorial-page__content-step-2__skills-item--progress-bar"
                  style={{
                    width: `${skill.value}%`,
                    maxWidth: skill.type === 'level' ? `${MAX_LEVEL * 10}%` : '100%',
                  }}
                  initial={{ width: '0%' }}
                  animate={{ width: `${animatedSkills[index].value}%` }}
                  transition={{ duration: 1, ease: 'easeInOut' }}
                />
                {skill.type === 'level' && (
                  <div
                    className="tutorial-page__content-step-2__skills-item--progress-bar--disabled"
                    style={{ width: `${(10 - MAX_LEVEL) * 10}%` }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="tutorial-page__content-step-2__luck">
          <h2>Your Snowy Luck:</h2>
          <Stars
            stars={animatedStars.map((star) => star.value)}
            isExtra={isExtraLuck}
            partStarIndex={partStarIndex}
            isAnimate={true}
          />
          {animatedStars.every((star) => star.isAnimated) && (
            <motion.p {...defaultAnimation}>Don't worry! You can boost it as you play!</motion.p>
          )}
        </div>
      </motion.div>
    ),
  };

  useEffect(() => {
    if (step === 2) {
      const animateSkills = () => {
        const skillAnimationDelay = 1000;
        skills.forEach((skill, index) => {
          setTimeout(() => {
            setAnimatedSkills((prev) => {
              const newSkills = [...prev];
              newSkills[index] = { value: skill.value, isAnimated: true };
              return newSkills;
            });
          }, index * skillAnimationDelay);
        });

        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      };

      animateSkills();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (animatedSkills.every((skill) => skill.isAnimated)) {
      setTimeout(() => {
        stars.forEach((value, index) => {
          setTimeout(() => {
            setAnimatedStars((prevStars) => {
              const updatedStars = [...prevStars];
              updatedStars[index] = { value, isAnimated: true };
              return updatedStars;
            });
          }, index * 500);
        });
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animatedSkills]);

  useEffect(() => {
    if (locationStep) {
      setStep(locationStep);
    }
  }, [locationStep]);

  return (
    <motion.div className="tutorial-page" {...defaultAnimation}>
      <div className="tutorial-page__header">
        <div
          className={classNames('tutorial-page__header-step', {
            'tutorial-page__header-step--active': step >= 0,
          })}
          onClick={() => setStep(0)}
        />
        <div
          className={classNames('tutorial-page__header-step', {
            'tutorial-page__header-step--active': step >= 1,
          })}
          onClick={() => setStep(1)}
        />
        <div
          className={classNames('tutorial-page__header-step', {
            'tutorial-page__header-step--active': step >= 2,
          })}
          onClick={() => setStep(2)}
        />
      </div>
      <div className="tutorial-page__content">
        <AnimatePresence mode="wait" initial={true}>
          {stepContent[step as keyof typeof stepContent]}
        </AnimatePresence>
      </div>
      <div className="tutorial-page__footer">
        <Button
          disabled={isLoading}
          color="ice-blue"
          text={buttonText}
          onClick={handleButtonClick}
        />
      </div>
      {step === 2 && animatedStars.every((star) => star.isAnimated) && (
        <motion.img
          className="tutorial-page__shine"
          src="/images/snow-season/shine.svg"
          alt=""
          initial={{ opacity: 0 }}
          animate={{ opacity: [0, 1, 0] }}
          transition={{
            opacity: {
              duration: 3,
              repeat: Infinity,
              ease: 'easeInOut',
            },
          }}
        />
      )}
    </motion.div>
  );
};

export default memo(Tutorial);
