import React, { memo } from 'react';
import classNames from 'classnames';

const DodgeIcon: React.FC<{
  className?: string;
  width?: number;
  height?: number;
  color?: string;
}> = ({ className, width = 14, height = 14, color = '#6FC4EE' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    className={classNames('dodge-icon', className)}>
    <g clipPath="url(#clip0_4190_33146)">
      <path
        d="M8.33403 2.85348C8.13877 3.04874 7.82218 3.04874 7.62692 2.85348L5.99027 1.21683C5.79501 1.02157 5.47843 1.02157 5.28317 1.21683L4.23246 2.26754C4.0372 2.4628 4.0372 2.77939 4.23246 2.97465L7.62692 6.36911C7.82218 6.56437 8.13877 6.56437 8.33403 6.36911L11.748 2.95512C11.9433 2.75985 11.9433 2.44327 11.748 2.24801L10.6973 1.1973C10.502 1.00204 10.1855 1.00204 9.9902 1.1973L8.33403 2.85348Z"
        fill={color}
      />
      <path
        d="M2.35348 8.12692C2.54875 8.32218 2.54875 8.63876 2.35348 8.83402L0.697307 10.4902C0.502045 10.6855 0.502044 11.002 0.697306 11.1973L1.74801 12.248C1.94328 12.4433 2.25986 12.4433 2.45512 12.248L5.86911 8.83402C6.06438 8.63876 6.06438 8.32218 5.86911 8.12692L2.47465 4.73245C2.27939 4.53719 1.96281 4.53719 1.76755 4.73245L0.716838 5.78316C0.521576 5.97842 0.521575 6.29501 0.716838 6.49027L2.35348 8.12692Z"
        fill={color}
      />
      <path
        d="M13.5254 12.2675C13.7206 12.4628 14.0372 12.4628 14.2325 12.2675L15.2832 11.2168C15.4784 11.0216 15.4784 10.705 15.2832 10.5097L13.6075 8.83402C13.4122 8.63876 13.4122 8.32218 13.6075 8.12692L15.2636 6.47074C15.4589 6.27548 15.4589 5.95889 15.2636 5.76363L14.2129 4.71292C14.0177 4.51766 13.7011 4.51766 13.5058 4.71292L10.0918 8.12692C9.89657 8.32218 9.89657 8.63876 10.0918 8.83402L13.5254 12.2675Z"
        fill={color}
      />
      <path
        d="M5.26364 15.7636C5.4589 15.9589 5.77548 15.9589 5.97074 15.7636L7.62692 14.1075C7.82218 13.9122 8.13876 13.9122 8.33403 14.1075L10.0097 15.7832C10.205 15.9784 10.5216 15.9784 10.7168 15.7832L11.7676 14.7325C11.9628 14.5372 11.9628 14.2206 11.7676 14.0254L8.33403 10.5918C8.13877 10.3966 7.82218 10.3966 7.62692 10.5918L4.21293 14.0058C4.01767 14.2011 4.01767 14.5177 4.21293 14.7129L5.26364 15.7636Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4190_33146">
        <rect width={16} height={17} fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default memo(DodgeIcon);
