import React, { memo } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import Opponent from './components/Opponent';
import Player from './components/Player';
import { EBattleTarget } from 'interface/battle';
import SnowballAnimation from './components/SnowballAnimation';
import SnowballCountdown from './components/Countdown';
import Points from './components/Points';
import { defaultAnimation } from 'styles/animations';
import { RoundType } from 'pages/snow-season/round/RoundPage';
import RoundButton from './components/RoundButton';
import RoundDetails from '../RoundDetails';
import { EAnimations } from 'utils/battle';
import ShieldIcon from 'assets/images/battle/ShieldIcon';
import SwordIcon from 'assets/images/battle/SwordIcon';
import DodgeIcon from 'assets/images/battle/DodgeIcon';
import { MAX_BATTLE_ROUNDS } from 'constants/battle';

import './Round.scss';

const newRoundOpacity = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 1 },
};

const slideVariants = {
  hidden: { y: '-289px' },
  visible: { y: 0 },
  exit: { y: '215px' },
};

interface IRoundProps {
  id: number;
  round: RoundType;
  attackTarget?: EBattleTarget | null;
  defenseTarget?: EBattleTarget | null;
  dodgeTarget?: EBattleTarget | null;
  animations: Record<EAnimations, boolean>;
  isDisabled?: boolean;
  onStartRound?: () => void;
  onNextRound?: () => void;
  selectDefense?: (target: EBattleTarget | null) => void;
  selectDodge?: (target: EBattleTarget | null) => void;
  selectAttack?: (target: EBattleTarget) => void;
  playerPoints?: number;
  opponentPoints?: number;
  playerLevel?: number;
  opponentLevel?: number;
  isBattleFinished?: boolean;
  isNextRoundButton?: boolean;
  detailsOpacity?: Record<
    EAnimations.DETAILS_1 | EAnimations.DETAILS_2 | EAnimations.DETAILS_3,
    number
  >;
}

const Round: React.FC<IRoundProps> = ({
  id,
  round,
  attackTarget,
  defenseTarget,
  dodgeTarget,
  animations,
  isDisabled,
  playerLevel,
  opponentLevel,
  onStartRound,
  onNextRound,
  selectAttack,
  selectDefense,
  selectDodge,
  isBattleFinished,
  detailsOpacity,
}) => {
  const isRoundButton = round.isCurrent && !isBattleFinished;
  return (
    <motion.div
      key={id}
      className={classNames('round', {
        'round--ended': !round.isCurrent || round.isFinishedAnimations,
      })}
      variants={round.isCurrent ? newRoundOpacity : slideVariants}
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5, delay: round.isCurrent ? 0.5 : 0 }}>
      <div className="round-title-wrapper">
        <h2 className="round-title">Round {id}</h2>
        <p className="round-subtitle__action-info">
          {round.isCurrent ? 'Select your actions!' : 'Ended'}
        </p>
      </div>
      <div className="round-subtitle">
        <div className="round-subtitle__actions">
          <p className="round-subtitle__action">
            <ShieldIcon color="#43C90F" /> Defend
          </p>
          <span>|</span>
          <p className="round-subtitle__action">
            <DodgeIcon color="#FFAD00" /> Dodge
          </p>
        </div>
        <p className="round-subtitle__action">
          <SwordIcon color="#FF0000" /> Attack
        </p>
      </div>
      <div className="round-selects">
        <div className="round-selects__inner">
          <Player
            level={playerLevel}
            defenseTarget={defenseTarget}
            dodgeTarget={dodgeTarget}
            selectDefense={selectDefense}
            selectDodge={selectDodge}
          />
          {round.isCurrent && (
            <SnowballAnimation
              isLeft
              target={round.details?.opponentAttackTarget}
              isAnimation={animations[EAnimations.SNOWBALL_2]}
              isLuck={
                animations[EAnimations.LUCK_ATTACK_1] || animations[EAnimations.LUCK_DEFEND_1]
              }
              isSuccessAttack={animations[EAnimations.ATTACK_SUCCESS_2]}
              isSuccessDefend={animations[EAnimations.DEFEND_SUCCESS_1]}
              isSuccessDodge={animations[EAnimations.DODGE_SUCCESS_1]}
            />
          )}
          <SnowballCountdown
            isPlay={round.isCurrent ? animations[EAnimations.COUNT_DOWN] : false}
          />
          {round.isCurrent && (
            <SnowballAnimation
              target={round.details?.playerAttackTarget}
              isAnimation={animations[EAnimations.SNOWBALL_1]}
              isLuck={
                animations[EAnimations.LUCK_ATTACK_2] || animations[EAnimations.LUCK_DEFEND_2]
              }
              isSuccessAttack={animations[EAnimations.ATTACK_SUCCESS_1]}
              isSuccessDefend={animations[EAnimations.DEFEND_SUCCESS_2]}
              isSuccessDodge={animations[EAnimations.DODGE_SUCCESS_2]}
            />
          )}
          <Opponent
            level={opponentLevel}
            isHeadSelected={attackTarget ? attackTarget === EBattleTarget.HEAD : false}
            isBodySelected={attackTarget ? attackTarget === EBattleTarget.BODY : false}
            isLegsSelected={attackTarget ? attackTarget === EBattleTarget.LEGS : false}
            onSelect={selectAttack ? selectAttack : () => {}}
          />
          <img className="round-selects__ice" src="/images/snow-season/ice/2.png" alt="" />
        </div>
        <Points
          isFinishedAnimations={round.isFinishedAnimations}
          playerPoints={round.playerPoints}
          opponentPoints={round.opponentPoints}
        />
        {round.details && !round.isFinishedAnimations && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.5 }}
            className={classNames('round-details-wrapper', {
              'round-details-wrapper--active-1': detailsOpacity?.[EAnimations.DETAILS_1],
              'round-details-wrapper--active-2': detailsOpacity?.[EAnimations.DETAILS_2],
              'round-details-wrapper--active-3': detailsOpacity?.[EAnimations.DETAILS_3],
            })}>
            <RoundDetails round={round.details} isFull={false} />
          </motion.div>
        )}
        {round.isCurrent && round.isFinishedAnimations && round.result && (
          <motion.img
            {...defaultAnimation}
            className="round-selects__battle-result"
            src={`/images/snow-season/battle/${round.result}.png`}
            alt=""
          />
        )}
      </div>
      {isRoundButton && (
        <>
          <RoundButton
            isNextRoundButton={id === MAX_BATTLE_ROUNDS ? false : round.isNextRoundButton}
            isDisabled={isDisabled}
            isHidden={round.isNextRoundButtonHidden}
            onNextRound={onNextRound}
            onStartRound={onStartRound}
          />
          {round.details && round.isNextRoundButton && (
            <motion.div className={'round-details-wrapper--end'}>
              <RoundDetails round={round.details} isFull={false} />
            </motion.div>
          )}
        </>
      )}
    </motion.div>
  );
};

export default memo(Round);
