import React, { useMemo } from 'react';
import {
  EBoostType,
  MAX_SPEED_BOOST_LEVEL,
  MAX_TIME_BOOST_LEVEL,
  SPEED_BOOST,
  STAR_ACCOUNT_1M_POINTS,
  STAR_ACCOUNT_1M_TICKETS,
  TIME_BOOST,
} from 'constants/boost';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import useFarmingStore from 'store/farming.store';
import useMainStore from 'store/main.store';

import './Boosts.scss';

const Boosts: React.FC = () => {
  const { showModal } = useModalStore();
  const timeBoostLevel = useFarmingStore((state) => state.timeBoostLevel);
  const speedBoostLevel = useFarmingStore((state) => state.speedBoostLevel);
  const starAccountAvailableUntil = useMainStore((state) => state.user?.starAccountAvailableUntil);

  const timeBoost = TIME_BOOST.get(timeBoostLevel || 1);
  const speedBoost = SPEED_BOOST.get(speedBoostLevel || 1);

  const starAccountPercent = useMemo(() => {
    if (!starAccountAvailableUntil) return 0;

    const availableUntil = new Date(starAccountAvailableUntil);
    const startTime = new Date(availableUntil);
    startTime.setMonth(startTime.getMonth() - 1);

    const now = new Date().getTime();
    const totalDuration = availableUntil.getTime() - startTime.getTime();
    const remainingDuration = availableUntil.getTime() - now;

    const percentLeft = (remainingDuration / totalDuration) * 100;
    return Math.max(0, Math.min(100, percentLeft));
  }, [starAccountAvailableUntil]);

  const isActive = useMemo(
    () => starAccountAvailableUntil && new Date(starAccountAvailableUntil) > new Date(),
    [starAccountAvailableUntil],
  );

  const onBoostClick = (boost: EBoostType) => {
    if (boost === EBoostType.STAR) {
      if (isActive) return;
      showModal(EModals.STAR_ACCOUNT_1M);
    } else {
      showModal(EModals.BOOST, {
        boost: boost,
        level: boost === EBoostType.SPEED ? speedBoostLevel : timeBoostLevel,
      });
    }
  };

  return (
    <div className="boosts">
      <h2 className="boosts-title">
        Boosts
        <img src="images/boost.png" alt="" style={{ width: '28px', height: '28px' }} />
      </h2>
      <div className="boosts-list">
        <div className="boosts-item" onClick={() => onBoostClick(EBoostType.STAR)}>
          <div className="boosts-item__star">
            <div
              className="boosts-item__star-progress"
              style={{ height: `${isActive ? starAccountPercent : 0}%` }}
            />
            <img className="boosts-item__star-base" src="images/star-boost.svg" alt="Star Boost" />
          </div>
          <div className="boosts-item-info">
            <h3>Star Boost</h3>
            <p className="item-reward item-reward--points">
              <img
                src="images/point.png"
                alt="Speed Points Reward"
                style={{ width: '16px', height: '16px' }}
              />
              <span>{STAR_ACCOUNT_1M_POINTS}</span>/ hour
            </p>
            <p className="item-reward item-reward--tickets">
              <img
                src="images/ticket.svg"
                alt="Speed Tickets Reward"
                style={{ width: '16px', height: '16px' }}
              />
              <span>{STAR_ACCOUNT_1M_TICKETS}</span>/ hour
            </p>
          </div>
        </div>
        <div className="boosts-item" onClick={() => onBoostClick(EBoostType.SPEED)}>
          <img src="images/boost-speed.png" alt="Speed" style={{ width: '40px', height: '40px' }} />
          <div className="boosts-item-info">
            <h3>
              Speed{' '}
              <label className="boost-level">
                {speedBoostLevel >= MAX_SPEED_BOOST_LEVEL ? 'MAX' : `LVL ${speedBoostLevel}`}
              </label>
            </h3>
            <p className="item-reward item-reward--points">
              <img
                src="images/point.png"
                alt="Speed Points Reward"
                style={{ width: '16px', height: '16px' }}
              />
              <span>{speedBoost!.miningSpeed}</span>/ hour
            </p>
            <p className="item-reward item-reward--tickets">
              <img
                src="images/ticket.svg"
                alt="Speed Tickets Reward"
                style={{ width: '16px', height: '16px' }}
              />
              <span>{speedBoost!.ticketsMiningSpeed}</span>/ hour
            </p>
          </div>
        </div>
        <div className="boosts-item" onClick={() => onBoostClick(EBoostType.TIME)}>
          <img src="images/boost-time.png" alt="Time" style={{ width: '40px', height: '40px' }} />
          <div className="boosts-item-info">
            <h3>
              Time{' '}
              <label className="boost-level">
                {timeBoostLevel >= MAX_TIME_BOOST_LEVEL ? 'MAX' : `LVL ${timeBoostLevel}`}
              </label>
            </h3>
            <p className="item-reward item-reward--time">
              <img
                src="images/boost-time-reward.png"
                alt="Time Reward"
                style={{ width: '16px', height: '16px' }}
              />
              <span>{timeBoost!.miningTime}</span> hours
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boosts;
