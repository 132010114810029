import React, { memo } from 'react';
import classNames from 'classnames';
import PlayerHead from '../PlayerHead';
import OpponentHead from '../OpponentHead';
import SwordIcon from 'assets/images/battle/SwordIcon';
import useBattleStore from 'store/battle.store';
import ShieldIcon from 'assets/images/battle/ShieldIcon';

import './Score.scss';

interface IScoreProps {
  playerLevel: number;
  opponentLevel: number;
  playerName: string;
  opponentName: string;
  playerPoints: number;
  opponentPoints: number;
  isIcons?: boolean;
  isHighlight?: boolean;
}

const Score: React.FC<IScoreProps> = ({
  playerLevel,
  opponentLevel,
  playerName,
  opponentName,
  playerPoints,
  opponentPoints,
  isIcons = true,
  isHighlight = false,
}) => {
  const username = useBattleStore((state) => state.profile?.username);
  const isPlayerWinner = playerPoints > opponentPoints;
  const isOpponentWinner = opponentPoints > playerPoints;

  return (
    <div className="score-container">
      <div
        className={classNames('score-info__player', {
          'score-info__player-active': isHighlight && username === playerName,
        })}>
        <PlayerHead level={playerLevel} />
        <p>{playerName}</p>
      </div>
      <p className="score-info__score">
        {isIcons && (
          <>
            <span className="score-info__score-icon-1">
              <SwordIcon color="#6FC4EE" />
            </span>
            {isPlayerWinner && (
              <img
                className="score-info__score-icon-1-crown"
                width={24}
                height={24}
                src="/images/snow-season/crown.gif"
                alt=""
              />
            )}
          </>
        )}
        <span>{playerPoints}</span>
        <span>:</span>
        <span>{opponentPoints}</span>
        {isIcons && (
          <>
            <span className="score-info__score-icon-2">
              <ShieldIcon color="#6FC4EE" />
            </span>
            {isOpponentWinner && (
              <img
                className="score-info__score-icon-2-crown"
                width={24}
                height={24}
                src="/images/snow-season/crown.gif"
                alt=""
              />
            )}
          </>
        )}
      </p>
      <div
        className={classNames('score-info__player', {
          'score-info__player-active': isHighlight && username === opponentName,
        })}>
        <p>{opponentName}</p>
        <OpponentHead level={opponentLevel} />
      </div>
    </div>
  );
};

export default memo(Score);
