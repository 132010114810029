import React, { memo } from 'react';
import Score from '../Score';

import './BattleInfo.scss';

interface IBattleInfoProps {
  playerPoints?: number;
  opponentPoints?: number;
  playerLevel?: number;
  opponentLevel?: number;
  playerName?: string;
  opponentName?: string;
}

const BattleInfo: React.FC<IBattleInfoProps> = ({
  playerLevel = 1,
  opponentLevel = 1,
  playerPoints = 0,
  opponentPoints = 0,
  playerName = 'Player',
  opponentName = 'Opponent',
}) => {
  return (
    <div className="battle-info">
      <Score
        playerLevel={playerLevel}
        opponentLevel={opponentLevel}
        playerName={playerName}
        opponentName={opponentName}
        playerPoints={playerPoints}
        opponentPoints={opponentPoints}
        isIcons={false}
      />
      <img className="battle-info__ice-1" src="/images/snow-season/ice/1.png" alt="" />
      <img className="battle-info__ice-2" src="/images/snow-season/ice/3.png" alt="" />
    </div>
  );
};

export default memo(BattleInfo);
