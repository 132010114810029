import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import UserImg from '../UserImg/UserImg';
import useMainStore from 'store/main.store';
import useBattleStore from 'store/battle.store';
import Gems from '../Gems';
import { EModals } from 'interface/modals';
import useModalStore from 'store/modals.store';

import './Header.scss';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const showModal = useModalStore((state) => state.showModal);
  const level = useMainStore((state) => state.user?.level);
  const username = useMainStore((state) => state.user?.username);
  const gems = useBattleStore((state) => state.profile?.score);

  const handleShowTutorial = (step: number = 2) => {
    navigate(`/snow-season/tutorial?step=${step}`);
  };

  const onFAQClick = () => {
    showModal(EModals.SNOW_FAQ_MODAL, {
      onShowTutorial: () => handleShowTutorial(1),
    });
  };

  return (
    <div className="snow-header">
      <div className="snow-header__left">
        <UserImg level={level} size="small" />
        <div className="snow-header__left-info">
          <span className="snow-header__left-info-username">{username}</span>
        </div>
      </div>
      <div className="snow-header__right">
        <Gems gems={gems} />
        <img
          onClick={onFAQClick}
          className="snow-header__right-faq"
          src="/images/snow-season/faq/faq.png"
          alt="?"
        />
      </div>
    </div>
  );
};

export default memo(Header);
