import { useCallback } from 'react';
import { getBattleProfile } from 'api/battle';
import useBattleStore from 'store/battle.store';
import useModalStore from 'store/modals.store';

export const useBoosLuckPurchase = () => {
  const closeModal = useModalStore((state) => state.closeModal);
  const setProfile = useBattleStore((state) => state.setProfile);

  const handleBoostLuckPurchase = useCallback(async () => {
    const profile = await getBattleProfile();
    if (profile) setProfile(profile);
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return handleBoostLuckPurchase;
};
