import { useCallback } from 'react';
import useMainStore from 'store/main.store';
import { getUser } from 'api/user';
import useModalStore from 'store/modals.store';

export const useStarBoostPurchase = () => {
  const closeModal = useModalStore((state) => state.closeModal);
  const setUser = useMainStore((state) => state.setUser);

  const handleStarBoosPurchase = useCallback(async () => {
    const user = await getUser();
    if (user) setUser(user);
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return handleStarBoosPurchase;
};
