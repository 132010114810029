import api from './APIService';
import { IUser } from 'interface/user';
import { showSuccessToast } from 'utils/toastUtils';
import { handleApiError } from './errorHandlerService';
import { IReferralEarning } from 'interface/referrals';

export const fetchReferrals = async (page: number, limit: number): Promise<IReferralEarning[]> => {
  try {
    const response = await api.get(`/referral?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return [];
  }
};

export const fetchReferralsCount = async (): Promise<number> => {
  try {
    const response = await api.get(`/referral/count`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    return 0;
  }
};

export const claimReferralsReward = async (): Promise<number> => {
  try {
    const response = await api.get<IUser>('/users/claim-referral');
    if (!response?.data) {
      return 0;
    }

    showSuccessToast('Claim referrals reward success!');
    return response.data.coins;
  } catch (error: any) {
    handleApiError(error);
    return 0;
  }
};
