import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { serverUrl } from 'config';
import { getItem } from 'utils/storage';

const API_URL = serverUrl;
// const API_URL = 'https://6443-188-163-49-40.ngrok-free.app';
const BASE_URL = API_URL + '/v1/api';

const api: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 20000,
});

api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = getItem('token');
    // const hash = getItem('hash');
    if (token) {
      config.headers = config.headers ?? {};
      config.headers['Authorization'] = `Bearer ${token}`;
      // config.headers['X-Telegram-Hash'] = hash;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error) => {
    if (error.response) {
      console.error('API Error:', error.response.data.message || error.message);
    }
    return Promise.reject(error);
  },
);

export default api;
