import { getPurchase, purchaseProduct } from 'api/products';
import { EProductId, EPurchaseStatus } from 'interface/product';
import { useCallback, useEffect } from 'react';
import useMainStore from 'store/main.store';
import useProductsStore from 'store/products.store';
import { handlePurchaseStatus, openPurchaseLink } from 'utils/purchase';
import { showErrorToast } from 'utils/toastUtils';
import { useAutoBattlePurchase } from './useAutoBattlePurchase';
import { useBoosLuckPurchase } from './useBoosLuckPurchase';
import { sleep } from 'utils/time';
import { useStarBoostPurchase } from './useStarBoostPurchase';
import { useBoostPurchase } from './useBoostPurchase';
import { useAutoClaimPurchase } from './useAutoClaimPurchase';
import { useLevelPurchase } from './useLevelPurchase';
import { useBattleEnergyPurchase } from './useBattleEnergyPurchase';

const isLevelPurchase = (productId: EProductId) =>
  [
    EProductId.LEVEL_2,
    EProductId.LEVEL_3,
    EProductId.LEVEL_4,
    EProductId.LEVEL_5,
    EProductId.LEVEL_6,
    EProductId.LEVEL_7,
    EProductId.LEVEL_8,
    EProductId.LEVEL_9,
  ].includes(productId as EProductId);

const isAutoClaimPurchase = (productId: EProductId) =>
  [EProductId.AUTO_CLAIM_1MONTH, EProductId.AUTO_CLAIM_1WEEK, EProductId.AUTO_CLAIM_48h].includes(
    productId as EProductId,
  );

const isSpeedBoostPurchase = (productId: EProductId) =>
  [
    EProductId.SPEED_BOOST_6,
    EProductId.SPEED_BOOST_7,
    EProductId.SPEED_BOOST_8,
    EProductId.SPEED_BOOST_9,
    EProductId.SPEED_BOOST_11,
    EProductId.SPEED_BOOST_12,
    EProductId.SPEED_BOOST_13,
    EProductId.SPEED_BOOST_14,
    EProductId.SPEED_BOOST_15,
    EProductId.SPEED_BOOST_16,
    EProductId.SPEED_BOOST_17,
    EProductId.SPEED_BOOST_18,
    EProductId.SPEED_BOOST_19,
    EProductId.SPEED_BOOST_20,
  ].includes(productId as EProductId);

const isTimeBoostPurchase = (productId: EProductId) =>
  [
    EProductId.TIME_BOOST_6,
    EProductId.TIME_BOOST_7,
    EProductId.TIME_BOOST_8,
    EProductId.TIME_BOOST_9,
    EProductId.TIME_BOOST_10,
    EProductId.TIME_BOOST_11,
    EProductId.TIME_BOOST_12,
    EProductId.TIME_BOOST_13,
    EProductId.TIME_BOOST_14,
    EProductId.TIME_BOOST_15,
    EProductId.TIME_BOOST_16,
    EProductId.TIME_BOOST_17,
    EProductId.TIME_BOOST_18,
    EProductId.TIME_BOOST_19,
  ].includes(productId as EProductId);

export const usePurchases = () => {
  const tg = useMainStore((state) => state.tgWebApp);
  const productId = useProductsStore((state) => state.productId);
  const purchaseId = useProductsStore((state) => state.purchaseId);
  const setProductId = useProductsStore((state) => state.setProductId);
  const setPurchaseId = useProductsStore((state) => state.setPurchaseId);
  const setIsPurchasing = useProductsStore((state) => state.setIsPurchasing);

  const handleLevelPurchase = useLevelPurchase();
  const handleAutoBattlePurchase = useAutoBattlePurchase();
  const handleBoostLuckPurchase = useBoosLuckPurchase();
  const handleStarBoostPurchase = useStarBoostPurchase();
  const handleAutoClaimPurchase = useAutoClaimPurchase();
  const handleBattleEnergyPurchase = useBattleEnergyPurchase();
  const { handleSpeedBoostPurchase, handleTimeBoostPurchase } = useBoostPurchase();

  const buyProduct = useCallback(
    async (productKey: EProductId) => {
      const response = await purchaseProduct(productKey);
      setPurchaseId(response.id);
      openPurchaseLink(tg, response.purchaseUrl);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tg],
  );

  const fetchPurchase = useCallback(
    // eslint-disable-next-line no-empty-pattern
    async ({ status }: { url: string; status: 'paid' | 'cancelled' | 'failed' | 'pending' }) => {
      try {
        setIsPurchasing(true);
        if (!purchaseId) {
          showErrorToast('Cannot get purchase id.');
          throw new Error('Cannot get purchase id.');
        }
        if (status === 'failed') {
          showErrorToast('Purchase failed, please try again later...');
          throw new Error('Purchase failed, please try again later...');
        }
        await sleep(1000);
        const purchase = await getPurchase(purchaseId);
        if (!purchase) {
          showErrorToast('Purchase failed, please try again later...');
          throw new Error('Purchase failed, please try again later...');
        }

        if (purchase.status === EPurchaseStatus.COMPLETED) {
          handlePurchaseStatus(purchase.status);
          if (purchase.product.id === EProductId.AUTO_BATTLE) {
            await handleAutoBattlePurchase(purchaseId);
          }
          if (purchase.product.id === EProductId.BOOST_LUCK) {
            await handleBoostLuckPurchase();
          }
          if (purchase.product.id === EProductId.STAR_ACCOUNT_1M) {
            await handleStarBoostPurchase();
          }
          if (isLevelPurchase(purchase.product.id as EProductId)) {
            await handleLevelPurchase();
          }
          if (isSpeedBoostPurchase(purchase.product.id as EProductId)) {
            await handleSpeedBoostPurchase();
          }
          if (isTimeBoostPurchase(purchase.product.id as EProductId)) {
            await handleTimeBoostPurchase();
          }
          if (isAutoClaimPurchase(purchase.product.id as EProductId)) {
            await handleAutoClaimPurchase();
          }
          if (purchase.product.id === EProductId.BATTLE_ENERGY_REFILL) {
            await handleBattleEnergyPurchase();
          }
        }
      } catch (error) {
      } finally {
        setProductId(null);
        setPurchaseId(null);
        setIsPurchasing(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [purchaseId],
  );

  useEffect(() => {
    if (productId) buyProduct(productId);
  }, [productId, buyProduct]);

  useEffect(() => {
    tg?.onEvent('invoiceClosed', fetchPurchase);
    return () => {
      tg?.offEvent('invoiceClosed', fetchPurchase);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPurchase]);
};
