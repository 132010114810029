import { SEASON_REWARDS } from 'constants/battle';
import { EBattleAttackResult, EBattleDefenseResult, IRound } from 'interface/battle';

export const getAttackPoints = (attackResult: EBattleAttackResult): number => {
  const attackPointsMap: Record<EBattleAttackResult, number> = {
    [EBattleAttackResult.SUCCESS]: 2,
    [EBattleAttackResult.BLOCKED]: 0,
    [EBattleAttackResult.DODGED]: 0,
    [EBattleAttackResult.LUCKY_SUCCESS]: 3,
    [EBattleAttackResult.LUCKY_BLOCKED]: 1,
    [EBattleAttackResult.LUCKY_DODGED]: 0,
    [EBattleAttackResult.UNLUCKY_SUCCESS]: 1,
    [EBattleAttackResult.UNLUCKY_BLOCKED]: 0,
    [EBattleAttackResult.UNLUCKY_DODGED]: 0,
  };

  return attackPointsMap[attackResult];
};

export const getDefensePoints = (defenseResult: EBattleDefenseResult): number => {
  const defensePointsMap: Record<EBattleDefenseResult, number> = {
    [EBattleDefenseResult.BLOCKED]: 0,
    [EBattleDefenseResult.FAILED]: 0,
    [EBattleDefenseResult.DODGED]: 2,
    [EBattleDefenseResult.LUCKY_BLOCKED]: 1,
    [EBattleDefenseResult.LUCKY_FAILED]: 0,
    [EBattleDefenseResult.LUCKY_DODGED]: 3,
    [EBattleDefenseResult.UNLUCKY_BLOCKED]: 0,
    [EBattleDefenseResult.UNLUCKY_FAILED]: 0,
    [EBattleDefenseResult.UNLUCKY_DODGED]: 1,
  };

  return defensePointsMap[defenseResult];
};

export const isDefenseLuck = (result: EBattleDefenseResult) =>
  result === EBattleDefenseResult.LUCKY_BLOCKED ||
  result === EBattleDefenseResult.LUCKY_DODGED ||
  result === EBattleDefenseResult.LUCKY_FAILED;

export const isAttackLuck = (result: EBattleAttackResult) =>
  result === EBattleAttackResult.LUCKY_BLOCKED ||
  result === EBattleAttackResult.LUCKY_DODGED ||
  result === EBattleAttackResult.LUCKY_SUCCESS;

export const getRank = (position: number): number => {
  const rewards = Object.entries(SEASON_REWARDS).map(([rank, reward]) => ({
    rank: parseInt(rank, 10),
    reward,
  }));

  for (const { rank } of rewards) {
    if (position <= rank) {
      return rank as keyof typeof SEASON_REWARDS;
    }
  }

  return 0;
};

export enum EAnimations {
  COUNT_DOWN = 'countdown',
  SNOWBALL_1 = 'snowball1',
  SNOWBALL_2 = 'snowball2',
  ATTACK_SUCCESS_1 = 'attackSuccess1',
  DEFEND_SUCCESS_1 = 'defendSuccess1',
  DODGE_SUCCESS_1 = 'dodgeSuccess1',
  ATTACK_SUCCESS_2 = 'attackSuccess2',
  DEFEND_SUCCESS_2 = 'defendSuccess2',
  DODGE_SUCCESS_2 = 'dodgeSuccess2',
  LUCK_ATTACK_1 = 'luckAttack1',
  LUCK_ATTACK_2 = 'luckAttack2',
  LUCK_DEFEND_1 = 'luckDefend1',
  LUCK_DEFEND_2 = 'luckDefend2',
  DETAILS_1 = 'details_1',
  DETAILS_2 = 'details_2',
  DETAILS_3 = 'details_3',
}

export const getAnimations = (
  battleRound: IRound,
): Record<EAnimations, { start: number; end: number; isActive: boolean }> => {
  const isOpponentAttackSuccess =
    battleRound.opponentAttackResult === EBattleAttackResult.SUCCESS ||
    battleRound.opponentAttackResult === EBattleAttackResult.LUCKY_SUCCESS;
  const isOpponentDodged =
    battleRound.opponentDefenseResult === EBattleDefenseResult.DODGED ||
    battleRound.opponentDefenseResult === EBattleDefenseResult.LUCKY_DODGED;
  const isOpponentDefended =
    battleRound.opponentDefenseResult === EBattleDefenseResult.BLOCKED ||
    battleRound.opponentDefenseResult === EBattleDefenseResult.LUCKY_BLOCKED;

  const isPlayerAttackSuccess =
    battleRound.playerAttackResult === EBattleAttackResult.SUCCESS ||
    battleRound.playerAttackResult === EBattleAttackResult.LUCKY_SUCCESS;
  const isPlayerDodged =
    battleRound.playerDefenseResult === EBattleDefenseResult.DODGED ||
    battleRound.playerDefenseResult === EBattleDefenseResult.LUCKY_DODGED;
  const isPlayerDefended =
    battleRound.playerDefenseResult === EBattleDefenseResult.BLOCKED ||
    battleRound.playerDefenseResult === EBattleDefenseResult.LUCKY_BLOCKED;

  const isPlayerDefenseLuck = isDefenseLuck(battleRound.playerDefenseResult);
  const isPlayerAttackLuck = isAttackLuck(battleRound.playerAttackResult);

  const isOpponentDefenseLuck = isDefenseLuck(battleRound.opponentDefenseResult);
  const isOpponentAttackLuck = isAttackLuck(battleRound.opponentAttackResult);

  const animations: Record<EAnimations, { start: number; end: number; isActive: boolean }> = {
    [EAnimations.COUNT_DOWN]: { start: 0, end: 2000, isActive: true },

    [EAnimations.SNOWBALL_1]: { start: 0, end: 2000, isActive: true },
    [EAnimations.SNOWBALL_2]: { start: 0, end: 2000, isActive: true },

    [EAnimations.LUCK_ATTACK_1]: { start: 0, end: 1000, isActive: isPlayerAttackLuck },
    [EAnimations.LUCK_ATTACK_2]: { start: 0, end: 1000, isActive: isOpponentAttackLuck },
    [EAnimations.LUCK_DEFEND_1]: { start: 0, end: 1000, isActive: isPlayerDefenseLuck },
    [EAnimations.LUCK_DEFEND_2]: { start: 0, end: 1000, isActive: isOpponentDefenseLuck },

    [EAnimations.ATTACK_SUCCESS_1]: { start: 0, end: 2000, isActive: isPlayerAttackSuccess },
    [EAnimations.DEFEND_SUCCESS_1]: { start: 0, end: 2000, isActive: isPlayerDefended },
    [EAnimations.DODGE_SUCCESS_1]: { start: 0, end: 2000, isActive: isPlayerDodged },

    [EAnimations.DETAILS_1]: { start: 0, end: 500, isActive: true },
    [EAnimations.DETAILS_2]: { start: 0, end: 500, isActive: true },
    [EAnimations.DETAILS_3]: { start: 0, end: 500, isActive: true },

    [EAnimations.ATTACK_SUCCESS_2]: { start: 0, end: 2000, isActive: isOpponentAttackSuccess },
    [EAnimations.DEFEND_SUCCESS_2]: { start: 0, end: 2000, isActive: isOpponentDefended },
    [EAnimations.DODGE_SUCCESS_2]: { start: 0, end: 2000, isActive: isOpponentDodged },
  };

  return animations;
};
