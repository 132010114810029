import React from 'react';
import { motion } from 'framer-motion';
import { formatNumber } from 'utils/formatNumber';
import TasksList from 'components/Tasks';
import { getTaskIcon, HAPI_SCORE_URL } from 'constants/tasks';
import { ETaskSlug, ETaskStatus, ETaskType, ITaskProgress } from 'interface/task';
import useBalanceStore from 'store/balance.store';
import {
  startBasicTask,
  submitHapiScoreTask,
  verifyFittonTask,
  verifyMeerkatTask,
  getTaskTemplate,
  createBasicTask,
  verifyYupalkaTask,
  verifyMitteTask,
  verifyMemecatTask,
  verifyAddupTask,
  verifyStocatTask,
  verifyD3xTask,
} from 'api/tasks';
import useMainStore from 'store/main.store';
import useTasksStore from 'store/tasks.store';
import {
  getGeneralTasksProgress,
  getGeneralTasksRender,
  getPartnersTasksProgress,
  getPartnersTasksRender,
} from 'store/tasks.selector';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import classNames from 'classnames';
import { showErrorToast } from 'utils/toastUtils';
import { showSuccessToast } from 'utils/toastUtils';
import { useBattleOpen } from 'hooks/snow/useBattleOpen';
import useBattleStore from 'store/battle.store';
import { defaultAnimation } from 'styles/animations';

import './Tasks.scss';

const Tasks: React.FC = () => {
  const { showModal } = useModalStore();
  const tg = useMainStore((state) => state.tgWebApp);
  const coins = useBalanceStore((state) => state.coins);
  const tickets = useBalanceStore((state) => state.tickets);
  const addCoins = useBalanceStore((state) => state.addCoins);
  const addTickets = useBalanceStore((state) => state.addTickets);
  const addRefills = useBattleStore((state) => state.addRefills);
  const partnersTasksRender = useTasksStore(getPartnersTasksRender);
  const generalTasksRender = useTasksStore(getGeneralTasksRender);
  const generalTasksProgress = useTasksStore(getGeneralTasksProgress);
  const partnersTasksProgress = useTasksStore(getPartnersTasksProgress);
  const updateGeneralTask = useTasksStore(({ updateGeneralTask }) => updateGeneralTask);
  const updateGeneralTaskStatus = useTasksStore(
    ({ updateGeneralTaskStatus }) => updateGeneralTaskStatus,
  );
  const updatePartnerTaskStatus = useTasksStore(
    ({ updatePartnerTaskStatus }) => updatePartnerTaskStatus,
  );
  const updateTask = useTasksStore(({ updateTask }) => updateTask);
  const { showAirdropAndPvPModal } = useBattleOpen();

  const onGeneralTaskClick = async (task: ITaskProgress) => {
    if (
      task.status === ETaskStatus.VERIFY_REQUESTED ||
      task.status === ETaskStatus.VERIFY_REJECTED
    ) {
      showModal(EModals.TASK_VERIFICATION_MODAL, { task, showAirdropAndPvPModal });
      return;
    }

    if (task.status === ETaskStatus.INITIAL) {
      let taskId = task.id;
      if (taskId === 'DEFAULT_ID') {
        const taskResp = await createBasicTask(task.template.slug);
        if (taskResp) {
          taskId = taskResp.id;
          updateGeneralTask(task.template.slug, taskResp);
        }
      }
      if (taskId) {
        await startBasicTask(taskId);
      } else {
        showErrorToast('Failed to start task, please try again later');
        return;
      }
    }

    if (task.template.slug === ETaskSlug.BASIC_BATTLE_ACCOUNT) {
      showAirdropAndPvPModal();
    } else {
      if (tg?.openLink) {
        if (
          task.template.slug === ETaskSlug.BASIC_TG ||
          task.template.slug === ETaskSlug.BASIC_CHAT
        ) {
          tg.openTelegramLink(task.template.url);
        } else {
          tg.openLink(task.template.url);
        }
      } else {
        window.open(task.template.url, '_blank', 'noopener noreferrer');
      }
    }
    updateGeneralTaskStatus(task.template.slug, ETaskStatus.VERIFY_REQUESTED);
  };

  const onHapiScoreTaskClick = async (task: ITaskProgress) => {
    const res = await submitHapiScoreTask();
    if (!res?.status) {
      if (tg?.openTelegramLink) {
        tg.openTelegramLink(HAPI_SCORE_URL);
      } else {
        window.open(HAPI_SCORE_URL, '_blank', 'noopener noreferrer');
      }
      return;
    }
    updatePartnerTaskStatus(task.template.slug, res.status);
    if (
      res.status === ETaskStatus.COMPLETED_CLAIMED ||
      res.status === ETaskStatus.COMPLETED_NOT_CLAIMED
    ) {
      addCoins(task.template.rewardCoins);
      addTickets(task.template.rewardTickets);
    }
  };

  const onSolHapiScoreTaskClick = async () => {
    showModal(EModals.HOT_SOL_CONNECT);
  };

  const onPartnerTaskClick = async (task: ITaskProgress) => {
    let modal: EModals = EModals.EMPTY;
    if (task.template.slug === ETaskSlug.PARTNER_FITTON) {
      modal = EModals.FITTON_CONNECT;
    } else if (task.template.slug === ETaskSlug.PARTNER_MEERKAT) {
      modal = EModals.MEERKAT_CONNECT;
    } else if (task.template.slug === ETaskSlug.PARTNER_YUPALKA) {
      modal = EModals.YUPALKA_CONNECT;
    } else if (task.template.slug === ETaskSlug.PARTNER_MITTE) {
      modal = EModals.MITTE_CONNECT;
    } else if (task.template.slug === ETaskSlug.PARTNER_MEMECAT) {
      modal = EModals.MEMECAT_CONNECT;
    } else if (task.template.slug === ETaskSlug.PARTNER_ADDUP) {
      modal = EModals.PARTNER_ADDUP;
    } else if (task.template.slug === ETaskSlug.PARTNER_STOCAT) {
      modal = EModals.PARTNER_STOCAT;
    } else if (task.template.slug === ETaskSlug.PARTNER_D3X) {
      modal = EModals.PARTNER_D3X;
    }

    let verifyTask: (() => Promise<ITaskProgress | null>) | undefined = () => Promise.resolve(null);
    if (task.template.slug === ETaskSlug.PARTNER_FITTON) {
      verifyTask = verifyFittonTask;
    } else if (task.template.slug === ETaskSlug.PARTNER_MEERKAT) {
      verifyTask = verifyMeerkatTask;
    } else if (task.template.slug === ETaskSlug.PARTNER_YUPALKA) {
      verifyTask = verifyYupalkaTask;
    } else if (task.template.slug === ETaskSlug.PARTNER_MITTE) {
      verifyTask = verifyMitteTask;
    } else if (task.template.slug === ETaskSlug.PARTNER_MEMECAT) {
      verifyTask = verifyMemecatTask;
    } else if (task.template.slug === ETaskSlug.PARTNER_ADDUP) {
      verifyTask = verifyAddupTask;
    } else if (task.template.slug === ETaskSlug.PARTNER_STOCAT) {
      verifyTask = verifyStocatTask;
    } else if (task.template.slug === ETaskSlug.PARTNER_D3X) {
      verifyTask = verifyD3xTask;
    }
    let isLimitExceeded = true;

    if (modal === EModals.MEERKAT_CONNECT) {
      const templates = await getTaskTemplate(ETaskType.PARTNER);
      const meerkatTask = templates.find((template) => template.slug === ETaskSlug.PARTNER_MEERKAT);
      isLimitExceeded = meerkatTask?.partnerData?.limitReached;
    }

    showModal(modal, {
      onSubmit: async () => {
        const resp = await verifyTask();
        if (resp) {
          updatePartnerTaskStatus(task.template.slug, resp.status);
          updateTask(task.template.slug, { startedAt: resp.startedAt });
          if (
            resp.status === ETaskStatus.COMPLETED_CLAIMED ||
            resp.status === ETaskStatus.COMPLETED_NOT_CLAIMED
          ) {
            addCoins(task.template.rewardCoins);
            addTickets(task.template.rewardTickets);
            addRefills(task.template?.rewardRefills ?? 0);
            showSuccessToast('Task successfully verified!.');
          } else {
            showErrorToast('Cannot verify task, please try again!');
          }
        }
      },
      isLimitExceeded,
    });
  };

  const onTaskClick = (task: ITaskProgress) => {
    if (task.template.slug === ETaskSlug.PARTNER_HAPI_SCORE) {
      onHapiScoreTaskClick(task);
    } else if (task.template.slug === ETaskSlug.PARTNER_SOL_HAPI_SCORE) {
      onSolHapiScoreTaskClick();
    } else if (
      task.template.slug === ETaskSlug.PARTNER_FITTON ||
      task.template.slug === ETaskSlug.PARTNER_MEERKAT ||
      task.template.slug === ETaskSlug.PARTNER_YUPALKA ||
      task.template.slug === ETaskSlug.PARTNER_MITTE ||
      task.template.slug === ETaskSlug.PARTNER_MEMECAT ||
      task.template.slug === ETaskSlug.PARTNER_ADDUP ||
      task.template.slug === ETaskSlug.PARTNER_STOCAT ||
      task.template.slug === ETaskSlug.PARTNER_D3X
    ) {
      onPartnerTaskClick(task);
    } else {
      throw new Error('Unknown task');
    }
  };

  return (
    <motion.div className="tasks-page" {...defaultAnimation}>
      <div className="tasks-page-header">
        <h4>Your balance</h4>
        <div className="tasks-page-header__balance">
          <p>
            {formatNumber(coins)}
            <img className="point" src="images/point.png" alt="Points" />
          </p>
          <p>
            {formatNumber(tickets)}
            <img className="ticket" src="images/ticket.svg" alt="Tickets" />
          </p>
        </div>
      </div>
      <div className="tasks-page-body">
        <TasksList label="General" completed={generalTasksProgress} className="general-tasks-list">
          {generalTasksRender.map((task, i) => (
            <li
              key={task.id + i}
              className={classNames(task.status ? task.status : '', task.template.slug)}
              onClick={() => onGeneralTaskClick(task)}>
              <img src={task.template.image} alt="" />
              <div className="task-info">
                <p className="task">
                  {task.isRequiredForBattles && (
                    <img width={16} height={16} src="/images/snow-season/snowflake.gif" alt="*" />
                  )}{' '}
                  {task.template.title}
                </p>
                <div className="task-info__rewards">
                  {!!task.template.rewardTickets && (
                    <p className="reward reward-tickets">
                      <img src="images/ticket.png" alt="Point" />
                      <span>+{formatNumber(task.template.rewardTickets)}</span>
                    </p>
                  )}
                  <p className="reward">
                    <img src="images/point.png" alt="Tickets" />
                    <span>+{task.template.rewardCoins}</span>
                  </p>
                </div>
              </div>
              <p className="status-icon">
                <img src={getTaskIcon(task.status)} alt="" />
              </p>
            </li>
          ))}
        </TasksList>
        <TasksList
          label="Specials"
          completed={partnersTasksProgress}
          isDisabled={false}
          className="special-tasks-list">
          {partnersTasksRender.map((task, i) => (
            <li
              className={classNames(task.status ? task.status : '', task.template.slug)}
              key={i}
              onClick={() => onTaskClick(task)}>
              <img src={task.template.image} alt="" />
              <div className="task-info">
                <p className="task">
                  {task.isRequiredForBattles && (
                    <img width={16} height={16} src="/images/snow-season/snowflake.gif" alt="*" />
                  )}{' '}
                  {task.template.title}
                </p>
                <p className="reward">
                  {!!task.template.rewardTickets && (
                    <span className="reward-item reward-item--tickets">
                      <img src="images/ticket.svg" alt="Tickets" />
                      <span>{task.template.rewardTickets}</span>
                    </span>
                  )}
                  {!!task.template.rewardCoins && (
                    <span className="reward-item reward-item--coins">
                      <img src="images/point.png" alt="Tickets" />
                      <span>+{formatNumber(task.template.rewardCoins)}</span>
                    </span>
                  )}
                  {!!task.template.rewardRefills && (
                    <span className="reward-item reward-item--coins">
                      <img src="images/snow-refill.png" alt="Tickets" />
                      <span>+{formatNumber(task.template.rewardRefills)}</span>
                    </span>
                  )}
                  {!!task.template.rewardStars && (
                    <span className="reward-item reward-item--luck">
                      <img src="/images/snow-season/star-active.png" alt="" />
                      <span>+{task.template.rewardStars}</span>
                    </span>
                  )}
                </p>
              </div>
              {task.status && (
                <p className="status-icon">
                  <img src={getTaskIcon(task.status)} alt="" />
                </p>
              )}
            </li>
          ))}
        </TasksList>
      </div>
    </motion.div>
  );
};

export default Tasks;
