import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button/Button';
import { defaultAnimation } from 'styles/animations';
import useAppStore from 'store/app.store';

import './SeasonEndPage.scss';

const SeasonEndPage: React.FC = () => {
  const season = useAppStore((state) => state.season);
  const navigate = useNavigate();

  return (
    <motion.div className="season-end-page" {...defaultAnimation}>
      <div className="season-end-page-title">
        <h2>Season {season} ended!</h2>
        <p>Your performance is being calculated. Achievements and rewards are on the way!</p>
      </div>
      <img className="season-end-page-img" src="/images/snow-season/end.png" alt="Season-end" />
      <img
        className="season-end-page-shine shine-animation"
        src="/images/snow-season/shine-2.svg"
        alt=""
      />
      <img
        className="season-end-page-shine-bottom"
        src="/images/snow-season/end-bottom.png"
        alt=""
      />
      <Button
        className="season-end-page-button"
        text="Back to Main App"
        onClick={() => navigate('/')}
      />
    </motion.div>
  );
};

export default SeasonEndPage;
