import classNames from 'classnames';
import React, { memo } from 'react';
import PlayerHead from 'components/SnowSeason/PlayerHead';
import useBattleStore from 'store/battle.store';
import { formatNumber } from 'utils/formatNumber';
import { getLeaderTitle, getPlayersNextRank } from 'utils/leaderboard';

interface ICurrentUser {
  rank: number;
  gemsAmount: number;
  scores?: Record<string, number>;
}

const CurrentUser: React.FC<ICurrentUser> = ({ rank, gemsAmount, scores }) => {
  const username = useBattleStore((state) => state.profile?.username);
  const playerLevel = useBattleStore((state) => state.profile?.level);
  const showNextRank = rank !== 1;
  const { nextRank, nextScore } = getPlayersNextRank(rank, scores || {});

  return (
    <>
      <div className={classNames('current-user', `current-user--position-${rank}`)}>
        <PlayerHead level={playerLevel} />
        <div className="current-user-info">
          <h4>{username}</h4>
          <p className="current-user-info-gems">
            <img src="/images/snow-season/gem.png" alt="Gems" loading="lazy" />
            <span className="fs-12 fw-500">{formatNumber(gemsAmount, 'long', 'space')}</span>
          </p>
        </div>
        <div className="current-user-position">
          <p className="fs-16 fw-600">{rank ? `#${rank}` : 'N/A'}</p>
          <p className="fs-12 fw-500">Your place</p>
        </div>
      </div>
      {showNextRank && (
        <p className="user-not-in-top fs-14 fw-500 ">
          <span className="opacity-70">You need</span>{' '}
          <b className="fw-600">{Math.round((nextScore - gemsAmount) / 2)} wins</b>
          <span className="opacity-70">
            {' '}
            wins to #{nextRank} / {getLeaderTitle(nextRank)}
          </span>
        </p>
      )}
    </>
  );
};

export default memo(CurrentUser);
