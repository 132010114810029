import { validate as isValidUUID } from 'uuid';

const mobileRegex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
const iosRegex = /iPhone|iPad/i;

export function isMobile() {
  return (
    mobileRegex.test(navigator.userAgent) ||
    (navigator.maxTouchPoints > 0 && 'ontouchstart' in window)
  );
}

export function isUuid(value: string): boolean {
  return isValidUUID(value);
}
