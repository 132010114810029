import React from 'react';
import { EModals } from 'interface/modals';

const modalCache = new Map();

const importModalComponent = (path: string) => React.lazy(() => import(`${path}`));

export const lazyImportModal = (modalType: EModals) => {
  if (modalCache.has(modalType)) {
    return modalCache.get(modalType);
  } else {
    let path;
    switch (modalType) {
      case EModals.FAQ:
        path = './FAQModal';
        break;
      case EModals.GIFT:
        path = './GiftModal';
        break;
      case EModals.LEVEL_UPGRADE:
        path = './LevelUpgradeModal';
        break;
      case EModals.BOOST:
        path = './BoostModal';
        break;
      case EModals.STAR_ACCOUNT_1M:
        path = './StarBoostModal';
        break;
      case EModals.DAILY_REWARD:
        path = './DailyRewardModal';
        break;
      case EModals.NFT_REWARD:
        path = './NFTRewardModal';
        break;
      case EModals.NFTS_INFO_MODAL:
        path = './NFTsInfoModal';
        break;
      case EModals.NFT_STAKING_INFO_MODAL:
        path = './NFTStakingInfoModal';
        break;
      case EModals.NFT_UNSTAKE_MODAL:
        path = './NFTUnstakeModal';
        break;
      case EModals.NFT_STAKING_SELECT_MODAL:
        path = './NFTStakingSelectModal';
        break;
      case EModals.CONNECT_HOT_REWARD:
        path = './ConnectHotReward';
        break;
      case EModals.DISCONNECT_WALLET:
        path = './DisconnectWalletModal';
        break;
      case EModals.FITTON_CONNECT:
        path = './FittonConnectModal';
        break;
      case EModals.AUTO_CLAIM:
        path = './AutoClaimModal';
        break;
      case EModals.MEERKAT_CONNECT:
        path = './MeerkatConnectModal';
        break;
      case EModals.AIRDROP_AND_PVP:
        path = './AirdropAndPvPBattlesModal';
        break;
      case EModals.BATTLE_WAITING_MODAL:
        path = './BattleWaitingModal';
        break;
      case EModals.BATTLE_RESULT_MODAL:
        path = './BattleResultModal';
        break;
      case EModals.SNOWBALLS_FAQ_MODAL:
        path = './SnowballsFAQModal';
        break;
      case EModals.SNOW_FAQ_MODAL:
        path = './SnowFAQModal';
        break;
      case EModals.TASK_VERIFICATION_MODAL:
        path = './TaskVerificationModal';
        break;
      case EModals.BOOST_LUCK_MODAL:
        path = './BoostLuckModal';
        break;
      case EModals.SNOWBALLS_REFILL_MODAL:
        path = './SnowballsRefillModal';
        break;
      case EModals.BATTLE_PREPARE_MODAL:
        path = './BattlePrepareModal';
        break;
      case EModals.YUPALKA_CONNECT:
        path = './YupalkaConnectModal';
        break;
      case EModals.MITTE_CONNECT:
        path = './MitteConnectModal';
        break;
      case EModals.MEMECAT_CONNECT:
        path = './MemecatConnectModal';
        break;
      case EModals.SEASON_REWARDS:
        path = './SeasonRewardsModal';
        break;
      case EModals.SEASON_REWARDS_INFO:
        path = './SeasonRewardsInfoModal';
        break;
      case EModals.PARTNER_ADDUP:
        path = './AddupConnectModal';
        break;
      case EModals.PARTNER_D3X:
        path = './D3xConnectModal';
        break;
      case EModals.PARTNER_STOCAT:
        path = './StocatConnectModal';
        break;
      case EModals.HOT_SOL_CONNECT:
        path = './HotSolConnectModal';
        break;

      default:
        return null;
    }
    const LazyComponent = importModalComponent(path);
    modalCache.set(modalType, LazyComponent);
    return LazyComponent;
  }
};
