import React, { memo } from 'react';
import classNames from 'classnames';

const SwordIcon: React.FC<{
  className?: string;
  width?: number;
  height?: number;
  color?: string;
}> = ({ className, width = 14, height = 14, color = '#6FC4EE' }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    className={classNames('sword-icon', className)}>
    <g clipPath="url(#clip0_4214_25670)">
      <path
        d="M4.64898 7.69894C4.41251 7.46248 4.3764 7.09157 4.56277 6.81398L8.13953 1.4839C8.18408 1.41747 8.23496 1.35552 8.29164 1.29884C8.47855 1.11193 8.72055 0.989879 8.98179 0.950864L12.442 0.433383C12.77 0.384351 13.1019 0.493752 13.3362 0.728108C13.5706 0.962463 13.68 1.29436 13.6309 1.6223L13.1132 5.08254C13.0742 5.34378 12.9521 5.58578 12.7652 5.77269C12.7085 5.82937 12.6466 5.88024 12.5802 5.9248L7.25035 9.50156C6.9725 9.68793 6.60185 9.65182 6.36539 9.41535L6.33824 9.3882C6.06276 9.11272 6.06513 8.6651 6.34404 8.39252L9.23987 5.56232C9.43258 5.3741 9.4389 5.06646 9.25437 4.87006C9.06483 4.6684 8.74664 4.6618 8.54867 4.8553L5.63306 7.70474C5.3589 7.97257 4.92024 7.9702 4.64898 7.69894ZM9.15499 9.95788C8.89216 9.70111 8.4854 9.66368 8.18013 9.86851L7.00282 10.6586C6.69491 10.8652 6.28393 10.8252 6.02163 10.5629L3.50119 8.04243C3.23889 7.78013 3.19882 7.36915 3.40549 7.06125L4.19556 5.88393C4.40039 5.57893 4.36295 5.1719 4.10619 4.90908C3.76217 4.55715 3.18142 4.6104 2.90699 5.01901L2.12036 6.19105C1.48873 7.13243 1.61105 8.38909 2.41271 9.19075L2.88828 9.66632L2.48626 10.0876C2.22159 10.3522 1.83513 10.4609 1.47423 10.362C1.12204 10.2655 0.729515 10.3551 0.451926 10.6306C0.0354108 11.0442 0.0478008 11.7233 0.462998 12.1383L1.92581 13.6011C2.34575 14.021 3.03432 14.0289 3.44609 13.6011C3.71234 13.3243 3.79802 12.9381 3.70312 12.5906C3.60426 12.2295 3.71287 11.8433 3.97754 11.5786L4.38009 11.1579L4.87332 11.6511C5.67498 12.4528 6.93164 12.5753 7.87302 11.9434L9.04506 11.1568C9.45367 10.8829 9.50718 10.3022 9.15499 9.95788Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4214_25670">
        <rect
          width="13.4972"
          height="13.4972"
          fill="white"
          transform="translate(0.145264 0.421875)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default memo(SwordIcon);
