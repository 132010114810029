import { EPurchaseStatus, IPurchase } from 'interface/product';
import api from './APIService';

export const getProducts = async () => {
  try {
    const response = await api.get('products');
    return response.data;
  } catch (error) {
    return [];
  }
};

export const purchaseProduct = async (productId: string): Promise<IPurchase> => {
  const response = await api.post('purchases', { productId });
  return response.data;
};

export const getPurchase = async (purchaseId: string): Promise<IPurchase | null> => {
  const maxRetries = 5;
  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      const response = await api.get<IPurchase | null>(`purchases/${purchaseId}`);
      if (response.data?.status === EPurchaseStatus.PENDING) {
        throw new Error('Purchase in pending');
      }
      return response.data;
    } catch (error) {
      if (attempt === maxRetries) {
        console.error(`Failed after ${maxRetries} attempts`, error);
        return null;
      }
      const delayTime = Math.pow(2, attempt) * 500;
      console.log(`Retrying in ${delayTime / 1000} seconds... (${attempt}/${maxRetries})`);
      await delay(delayTime);
    }
  }

  return null;
};
