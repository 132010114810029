type GTagEventParams = {
  user_id?: string;
  [key: string]: any;
};

export const logGAEvent = (eventName: string, params: GTagEventParams = {}): void => {
  if (window.gtag) {
    window.gtag('event', eventName, params);
  } else {
    console.warn('gtag is not loaded');
  }
};

export const setGAUserId = (user_id: string): void => {
  if (!user_id) return;
  if (!window.gtag) return;

  window.gtag('set', { user_id });
};
