import { useCallback } from 'react';
import { sleep } from 'utils/time';
import { getBattleProfile, getBattleRounds, getLastAutoBattle } from 'api/battle';
import useModalStore from 'store/modals.store';
import { EModals } from 'interface/modals';
import useBattleStore from 'store/battle.store';
import { showErrorToast } from 'utils/toastUtils';

export const useAutoBattlePurchase = () => {
  const closeModal = useModalStore((state) => state.closeModal);
  const showModal = useModalStore((state) => state.showModal);
  const setProfile = useBattleStore((state) => state.setProfile);

  const handleAutoBattlePurchase = useCallback(async (purchaseId: string) => {
    const battle = await getLastAutoBattle(purchaseId);
    if (battle) {
      const battleRounds = await getBattleRounds(battle?.id);
      closeModal();
      await sleep(300);
      showModal(EModals.BATTLE_RESULT_MODAL, {
        rounds: battleRounds,
        winnerId: battle.winnerId,
        playerId: battle.playerId,
        opponentId: battle.opponentId,
        playerScore: battle.playerScore,
        opponentScore: battle.opponentScore,
        playerName: battle.player?.username || '',
        opponentName: battle.opponent?.username || '',
        playerLevel: battle.player?.level || 0,
        opponentLevel: battle.opponent?.level || 0,
      });
    } else {
      const battleProfile = await getBattleProfile();
      if (battleProfile) setProfile(battleProfile);
      showErrorToast('Something went wrong, you will receive extra autobattle.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return handleAutoBattlePurchase;
};
